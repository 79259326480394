import React from 'react'

import { SimpleGrid } from '@chakra-ui/react'

import { LanguageSetup, PersonalInformation, TelegramBotSetup } from '.'

import { useReadMyUserProfile } from 'shared/queries/userProfile'

export const Settings = () => {
  const { data: user, refetch: refetchUserProfile } = useReadMyUserProfile()

  return (
    <SimpleGrid spacing="20px" columns={{ base: '1', md: '2' }}>
      <PersonalInformation user={user} refetchUserProfile={refetchUserProfile} />

      <LanguageSetup user={user} refetchUserProfile={refetchUserProfile} />

      <TelegramBotSetup user={user} refetchUserProfile={refetchUserProfile} />
    </SimpleGrid>
  )
}
