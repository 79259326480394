import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { omit } from 'lodash'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  useToast,
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FormField } from './FormField'

import { APP_PATHS } from 'paths'
import { useResetPasswordMutation } from 'shared/mutations/user'

export const ResetPasswordModal = (isResetPasswordModalOpen) => {
  const navigate = useNavigate()
  const params = useParams()
  const { t } = useTranslation()
  const toast = useToast()
  const { mutate: resetPassword } = useResetPasswordMutation()

  const ResetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(8, t(`Must Contain 8 Characters`))
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!@#$%^&*()\-_=+[{}\]|;:'",<.>/?€£¥₹])([a-zA-Z0-9`~!@#$%^&*()\-_=+[{}\]\\|;:'",<.>/?€£¥₹]+)(?=.{8,})/,
        t(`One Uppercase, One Lowercase, One Number and One Special Case Character`)
      )
      .required(t(`Please Enter your password`)),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], t(`Passwords does not match`))
      .required(t(`Please re-type your password`)),
  })

  const resetPasswordCancel = () => {
    navigate(APP_PATHS.home)
  }

  const handleResetPassword = async (values) => {
    values.token = params?.token
    const filteredValues = omit(values, ['confirmNewPassword'])

    resetPassword(filteredValues, {
      onSuccess: () => {
        toast({
          position: 'top-right',
          status: 'success',
          title: t(`Password successfully reset`),
          isClosable: true,
        })
        navigate(APP_PATHS.home)
      },
    })
  }

  return (
    <>
      <Modal isOpen={isResetPasswordModalOpen} onClose={resetPasswordCancel} isCentered>
        <ModalOverlay />
        <ModalContent maxW="350px">
          <ModalHeader>{t(`Reset Password`)}</ModalHeader>

          <Formik
            initialValues={{
              newPassword: '',
              confirmNewPassword: '',
            }}
            validationSchema={ResetPasswordSchema}
            onSubmit={(values) => {
              handleResetPassword(values)
            }}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <FormField
                    name="newPassword"
                    type="password"
                    placeholder={t(`Enter New Password`)}
                  />
                  <FormField
                    name="confirmNewPassword"
                    type="password"
                    placeholder={t(`Confirm New Password`)}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button size="lg" mr="16px" onClick={resetPasswordCancel}>
                    {t(`Cancel`)}
                  </Button>
                  <Button size="lg" type="submit">
                    {t(`Reset password`)}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  )
}
