import React from 'react'
import { useTranslation } from 'react-i18next'

import { TableContainer, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'

export const QUIZIncorrectWordsList = ({ words }) => {
  const { t } = useTranslation()
  return (
    <TableContainer
      border="1px"
      borderRadius="10px"
      borderColor="highliteLineGray"
      bg="mainGray"
      p="16px"
      m="16px"
      alignSelf="stretch"
      minW="350px"
    >
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th textAlign="center">{t('Incorrect word')}</Th>
            <Th textAlign="center">{t('Translation')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {words.map((wordItem) => (
            <Tr key={wordItem.id} borderBottom="1px" borderColor="highlightLineGray">
              <Td textAlign="center" whiteSpace="break-spaces">
                {wordItem.fromOptions[0]}
              </Td>
              <Td textAlign="center" whiteSpace="break-spaces">
                {wordItem.toOptions[0]}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
