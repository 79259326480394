import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Image,
  Badge,
  Text,
  CircularProgress,
  CircularProgressLabel,
  Tooltip,
  HStack,
  Link as ChakraLink,
} from '@chakra-ui/react'

import { PRESENT_TO_RECOMMEND, badgeTitleNew } from 'helpers'
import { Duration, RatingStars } from 'components/PageElements/MovieSeries'

export const CardVideoBase = ({
  id,
  href,
  imageUrl,
  title,
  tmdbRating,
  knownWordsPercentage,
  knownWordsCount,
  wordsCount,
  createdAt,
  duration,
}) => {
  const { t } = useTranslation()

  const badgeTitle = knownWordsPercentage >= PRESENT_TO_RECOMMEND

  return (
    <ChakraLink
      p="16px"
      bg="linear-gradient(168deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.07) 100%)"
      boxShadow="0px 4px 24px -1px rgba(0, 0, 0, 0.20)"
      borderRadius="20px"
      border="2px"
      borderColor="highliteLineGray"
      backdropFilter="blur(40px)"
      overflow="hidden"
      width="250px"
      height="480px"
      // as="a"
      as={ReactRouterLink}
      key={`${id}-card`}
      // href={href}
      to={href}
      transition="transform 0.2s ease-in-out"
      _hover={{
        transform: 'scale(1.02)',
      }}
      _focus={{
        boxShadow: 'none',
      }}
    >
      <Box position="relative" mb="48px">
        <Image src={imageUrl} alt={`Image for ${title}`} borderRadius="8px" />
        {badgeTitle && (
          <Badge position="absolute" top="8px" right="8px" colorScheme="teal" variant="solid">
            {t('RECOMMENDED')}
          </Badge>
        )}
        {badgeTitleNew(createdAt) && (
          <Badge position="absolute" top="8px" left="8px" colorScheme="green" variant="solid">
            {t('NEW')}
          </Badge>
        )}
        {knownWordsPercentage && (
          <Box position="relative">
            <Tooltip
              hasArrow
              label={
                <>
                  <Text>{t(`Known words`)}</Text>
                  <Text align="center">
                    {knownWordsCount} / {wordsCount}
                  </Text>
                </>
              }
              placement="bottom-end"
              bg="mainGray"
            >
              <Box position="absolute" bottom="-28px" right="8px" width="56px">
                <CircularProgress
                  color="teal"
                  size="56px"
                  thickness="10px"
                  value={knownWordsPercentage}
                  bg="filtersBackground"
                  borderRadius="9999px"
                >
                  <CircularProgressLabel color="white">{`${knownWordsPercentage}%`}</CircularProgressLabel>
                </CircularProgress>
              </Box>
            </Tooltip>
          </Box>
        )}

        {tmdbRating && (
          <HStack position="absolute" bottom="-36px" left="0px" spacing="4px">
            <RatingStars rating={tmdbRating} />
          </HStack>
        )}
      </Box>

      {title && (
        <Text
          fontSize="md"
          fontWeight="bold"
          isTruncated
          whiteSpace="break-spaces"
          noOfLines={2}
        >
          {title}
        </Text>
      )}
      {duration && <Duration duration={duration} />}
    </ChakraLink>
  )
}
