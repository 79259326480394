import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Badge,
  Image,
  Text,
  CircularProgress,
  CircularProgressLabel,
  Tooltip,
  Skeleton,
} from '@chakra-ui/react'

import { PRESENT_TO_RECOMMEND, badgeTitleNew } from 'helpers'

export const Poster = ({ infoData }) => {
  const { t } = useTranslation()

  // Badge for RECOMMENDED film
  const badgeTitle = infoData?.knownWordsPercentage >= PRESENT_TO_RECOMMEND
  const knownWords = Math.round(infoData?.knownWordsPercentage)

  if (!infoData) {
    return <Skeleton w="100%" h="600px" borderRadius="8px" mb="40px" />
  }

  return (
    <Box position="relative" mb="40px">
      <Image src={infoData?.imageUrl} alt={`Image for ${infoData?.title}`} borderRadius="8px" />
      {badgeTitle && (
        <Badge position="absolute" top="8px" right="8px" colorScheme="teal" variant="solid">
          {t('RECOMMENDED')}
        </Badge>
      )}
      {badgeTitleNew(infoData?.createdAt) && (
        <Badge position="absolute" top="8px" left="8px" colorScheme="green" variant="solid">
          {t('NEW')}
        </Badge>
      )}
      {knownWords && (
        <Box position="relative">
          <Tooltip
            hasArrow
            label={
              <>
                <Text>{t(`Known words`)}</Text>
                <Text align="center">
                  {infoData?.knownWordsCount} / {infoData?.wordsCount}
                </Text>
              </>
            }
            placement="bottom-end"
            bg="mainGray"
          >
            <Box position="absolute" bottom="-40px" right="8px" width="80px">
              <CircularProgress
                color="teal"
                size="80px"
                thickness="10px"
                value={knownWords}
                bg="filtersBackground"
                borderRadius="9999px"
              >
                <CircularProgressLabel color="white">{`${knownWords}%`}</CircularProgressLabel>
              </CircularProgress>
            </Box>
          </Tooltip>
        </Box>
      )}
    </Box>
  )
}
