import React from 'react'
import { useTranslation } from 'react-i18next'
import { groupBy } from 'lodash'

import { Box, VStack, Text, SkeletonText } from '@chakra-ui/react'

export const CrewList = ({ crewRecords }) => {
  const { t } = useTranslation()

  // Create new crew array for render
  const crewRecordsMap = groupBy(crewRecords, 'crewPersonId')
  const crewRecordsToDraw = Object.values(crewRecordsMap)
    .flat()
    .reduce((acc, item) => {
      const { crewPersonId, title, firstName, lastName } = item

      const existingEntry = acc.find((entry) => entry.crewPersonId === crewPersonId)
      if (existingEntry) {
        existingEntry.title.push(title) // Add title to existing entry
      } else {
        acc.push({
          // Create new entry
          crewPersonId,
          title: [title],
          firstName,
          lastName,
        })
      }
      return acc
    }, [])

  return (
    <VStack alignItems="flex-start" alignSelf="stretch" spacing="16px" pt="16px">
      {!crewRecords && <SkeletonText w="300px" noOfLines={8} spacing="4" skeletonHeight="5" />}

      {crewRecordsToDraw.map((crew) => (
        <Box key={crew.crewPersonId}>
          <Text fontSize="md">
            {crew.title.map(
              (title, index, array) => t(title) + (index < array.length - 1 ? ', ' : '')
            )}
          </Text>
          <Text fontSize="md" fontWeight="Bold">
            {crew.firstName} {crew.lastName}
          </Text>
        </Box>
      ))}
    </VStack>
  )
}
