import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from '@chakra-ui/react'

import isEmpty from 'lodash.isempty'

import { yearRelease } from 'helpers'
import { MainInfo, Overview, Poster } from 'components/PageElements/MovieSeries'

import { useDownloadSubtitle } from 'shared/queries/subtitles'
import { useReadFilm } from 'shared/queries/film'
import { Loading } from '.'

export const ServiceSubtitleUpload = ({
  selectedFile,
  setSelectedFile,
  fileContent,
  setFileContent,
  user,
  handleFileUpload,
  subtitleURL,
  videoId,
}) => {
  const { t } = useTranslation()

  const { data: filmData = {}, isLoading: isFilmDataLoading } = useReadFilm({
    id: videoId,
    includeReccomendationStats: true,
  })

  const { data: downloadSubtitle = {}, isLoading: isDownloadSubtitleLoading } =
    useDownloadSubtitle({
      path: subtitleURL,
    })

  useEffect(() => {
    if (!isEmpty(filmData)) {
      const fileName = filmData.title.replace(/\s/g, '_')
      const convertedReleaseYear = yearRelease(filmData?.releaseDate)
      const fullFileName =
        fileName + '_' + convertedReleaseYear + '_' + user.currentLearningLanguage + `.srt`
      const file = {
        name: fullFileName,
        title: filmData.title,
        id: filmData.id,
      }

      setSelectedFile(file)
    }
  }, [filmData])

  useEffect(() => {
    if (!isEmpty(downloadSubtitle)) {
      setFileContent(downloadSubtitle?.content)
    }
  }, [downloadSubtitle])

  useEffect(() => {
    if (fileContent && selectedFile) {
      handleFileUpload(selectedFile)
    }
  }, [fileContent, selectedFile])

  if (isFilmDataLoading || isDownloadSubtitleLoading) {
    return <Loading />
  }

  return (
    <Flex
      layerStyle="outline"
      flexWrap={{ base: 'wrap', lg: 'nowrap' }}
      alignItems="flex-start"
      justify="center"
      p={0}
      spacing={0}
    >
      {/* Left side */}
      <Box minWidth={{ base: '200px', sm: '350px' }} flexBasis="250px" px="24px" py="24px" m={0}>
        <Poster infoData={filmData} />
      </Box>
      {/* Right side */}
      <Box
        px="24px"
        minWidth={{ base: '100%', lg: '300px' }}
        flexBasis={{ base: '100%', lg: 'auto' }}
      >
        <MainInfo infoData={filmData} showKnownLabel={false} />
        <Overview description={filmData?.description} title={t('Overview')} />
      </Box>
    </Flex>
  )
}
