import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'

export const EmailWasVerified = ({ isOpen, onClose }) => {
  const { t } = useTranslation()

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      returnFocusOnClose={false}
      autoFocus={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t(`You email has been verified!`)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb="25px">
            {t(`Thank you so much for signing up. We hope you enjoy using this tool.`)}
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
