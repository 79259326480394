export const Modal = {
  baseStyle: {
    header: {
      color: 'white',
      bg: 'mainGray',
      px: '25px',
      pt: '16px',
      pb: '16px',
      borderRadius: '10px 10px 0 0',
      alignSelf: 'center',
      fontSize: '24',
      fontWeight: 'bold',
    },
    dialog: {
      bg: 'mainGray',
      borderRadius: '10px',
      border: '1px solid',
      borderColor: 'highliteLineGray',
    },
    closeButton: {
      top: '8px',
      right: '12px',
      _focus: {
        boxShadow: 'none',
      },
    },
    body: {
      bg: 'mainGray',
      px: '25px',
      py: '8px',
      borderRadius: '0 0 10px 10px',
    },
    footer: {
      justifyContent: 'center',
    },
  },
}
