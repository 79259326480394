import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

import { Link as ChakraLink } from '@chakra-ui/react'

import { Login } from '../components/authentication/login'
import {
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  CircularProgressLabel,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Switch,
  Tab,
  // TabIndicastor,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  // TableCaption,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  // Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  Select,
} from '@chakra-ui/react'
import { HamburgerIcon, ChevronDownIcon, AddIcon } from '@chakra-ui/icons'
import { LanguageSwitcher } from 'components/PageElements/helpers'
import { setDocumentTitle } from 'components/PageElements/helpers'
import { AnalysisSubtitles } from './AnalysisSubtitles'

export const MainTest = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  setDocumentTitle('TEST')

  return (
    <>
      <Box
        backgroundColor="gray.800"
        minH="100px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <LanguageSwitcher />{' '}
      </Box>
      <Box my="30px">
        <AnalysisSubtitles />
      </Box>

      <Login />
      <Button size="lg" variant="outline" colorScheme="teal">
        Log in
      </Button>
      <Button size="lg" variant="solid" colorScheme="teal">
        Log in
      </Button>
      <Button size="lg" variant="link" colorScheme="teal">
        Log in
      </Button>

      <Button size="md" variant="outline" colorScheme="teal">
        Log in
      </Button>
      <Button size="md" variant="solid" colorScheme="teal">
        Log in
      </Button>
      <Button size="md" variant="link" colorScheme="teal">
        Log in
      </Button>
      <Button onClick={onOpen}>Open Modal</Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Sit nulla est ex deserunt exercitation anim occaecat. Nostrud ullamco deserunt aute
              id consequat veniam incididunt duis in sint irure nisi. Mollit officia cillum Lorem
              ullamco minim nostrud elit officia tempor esse quis. Sunt ad dolore quis aute
              consequat. Magna exercitation reprehenderit magna aute tempor cupidatat consequat
              elit dolor adipisicing. Mollit dolor eiusmod sunt ex incididunt cillum quis. Velit
              duis sit officia eiusmod Lorem aliqua enim laboris do dolor eiusmod. Et mollit
              incididunt nisi consectetur esse laborum eiusmod pariatur proident Lorem eiusmod
              et. Culpa deserunt nostrud ad veniam.
            </Text>
            <FormControl>
              <FormLabel>First name</FormLabel>
              <Input placeholder="First name" />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button size="lg" variant="outline" onClick={onClose}>
              Login
            </Button>
            <Button size="lg" variant="outline">
              Secondary Action
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Tabs variant="unstyled">
        <TabList>
          <Tab>One</Tab>
          <Tab>Two</Tab>
          <Tab>Three</Tab>
        </TabList>
        {/* <TabIndicator mt="-1.5px" pr="0" pl="0" height="2px" bg="teal" borderRadius="1px" /> */}

        <TabPanels color="white">
          <TabPanel>
            <p>one!</p>
          </TabPanel>
          <TabPanel>
            <p>two!</p>
          </TabPanel>
          <TabPanel>
            <p>three!</p>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Badge variant="solid" colorScheme="teal">
        Recommended
      </Badge>
      <Box backgroundColor="white" minH="100px">
        <Tooltip hasArrow label="Bottom end" placement="bottom-end">
          <Button>End</Button>
        </Tooltip>
      </Box>

      <TableContainer
        border="1px"
        borderRadius="10px"
        borderColor="highliteLineGray"
        bg="mainGray"
        p="12px"
        maxWidth="700px"
      >
        <Table variant="simple">
          {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
          <Thead>
            <Tr>
              <Th>To convert</Th>
              <Th>into</Th>
              <Th isNumeric>multiply by</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>inches</Td>
              <Td>millimetres (mm)</Td>
              <Td isNumeric>25.4</Td>
            </Tr>
            <Tr>
              <Td>feet</Td>
              <Td>centimetres (cm)</Td>
              <Td isNumeric>30.48</Td>
            </Tr>
            <Tr>
              <Td>yards</Td>
              <Td>metres (m)</Td>
              <Td isNumeric>0.91444</Td>
            </Tr>
          </Tbody>
          {/* <Tfoot>
              <Tr>
                <Th>To convert</Th>
                <Th>into</Th>
                <Th isNumeric>multiply by</Th>
              </Tr>
            </Tfoot> */}
        </Table>
      </TableContainer>
      <Menu placement="bottom-end">
        <MenuButton
        // px={4}
        // py={2}
        // transition="all 0.2s"
        // borderRadius="md"
        // borderWidth="1px"
        // _hover={{ bg: 'gray.400' }}
        // _expanded={{ bg: 'blue.400' }}
        // _focus={{ boxShadow: 'outline' }}
        >
          <Avatar bg="teal.500" />
        </MenuButton>
        <MenuList>
          <MenuItem as="a" href="#">
            Profile
          </MenuItem>
          <MenuItem as="a" href="#">
            My vocabualry
          </MenuItem>
          <MenuItem as="a" href="#">
            What we do
          </MenuItem>
          <MenuDivider />
          <MenuItem onClick={() => alert('Kagebunshin')}>Log out</MenuItem>
        </MenuList>
      </Menu>
      <Box>
        <Menu placement="bottom-end">
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<HamburgerIcon />}
            variant="outline"
          />
          <MenuList>
            <Avatar bg="teal.500" size="sm" />
            <MenuItem command="⌘T">New Tab</MenuItem>
            <MenuItem command="⌘N">New Window</MenuItem>
            <MenuItem command="⌘⇧N">Open Closed Tab</MenuItem>
            <MenuItem command="⌘O">Open File...</MenuItem>
            {/* Add more MenuItems here */}
          </MenuList>
        </Menu>
      </Box>
      <Checkbox colorScheme="teal" defaultChecked>
        Checkbox
      </Checkbox>
      <Switch size="md" colorScheme="teal" />
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          colorScheme="teal"
          size="lg"
          variant="outline"
          rightIcon={<ChevronDownIcon />}
        >
          Genres
        </MenuButton>
        <MenuList minWidth="240px">
          {/* <MenuOptionGroup defaultValue="asc" title="Order" type="radio">
              <MenuItemOption value="asc">Ascending</MenuItemOption>
              <MenuItemOption value="desc">Descending</MenuItemOption>
            </MenuOptionGroup>
            <MenuDivider /> */}
          <MenuOptionGroup title="Country" type="checkbox">
            <MenuItemOption value="email">Email</MenuItemOption>
            <MenuItemOption value="phone">Phone</MenuItemOption>
            <MenuItemOption icon={<AddIcon />} value="country">
              Country
            </MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
      <Menu closeOnSelect={false}>
        <MenuButton as={Button} colorScheme="blue">
          Options
        </MenuButton>
        <MenuList minWidth="240px">
          <MenuOptionGroup type="checkbox">
            <MenuItemOption value="option1">Option 1</MenuItemOption>
            <MenuItemOption value="option2">Option 2</MenuItemOption>
            <MenuItemOption value="option3">Option 3</MenuItemOption>
            {/* Add more options as needed */}
          </MenuOptionGroup>
        </MenuList>
      </Menu>

      {/* //https://chakra-ui.com/docs/hooks/use-range-slider */}
      <Box m="20px">
        <RangeSlider
          aria-label={['min', 'max']}
          defaultValue={[5, 10]}
          min={0}
          max={10}
          // step={1}
          maxWidth="200px"
        >
          <RangeSliderTrack>
            <RangeSliderFilledTrack />
          </RangeSliderTrack>
          <RangeSliderThumb index={0} />
          <RangeSliderThumb index={1} />
        </RangeSlider>
      </Box>
      <Box m="20px">
        <CircularProgress
          value={30}
          size="48px"
          color="teal.500"
          bg="filtersBackground"
          borderRadius="9999px"
        >
          <CircularProgressLabel color="white">30%</CircularProgressLabel>
        </CircularProgress>
        <CircularProgress
          value={40}
          size="150px"
          color="teal.500"
          thickness="8px"
          bg="filtersBackground"
          borderRadius="9999px"
        >
          <CircularProgressLabel color="white">40%</CircularProgressLabel>
        </CircularProgress>
      </Box>

      <Box>
        <ChakraLink as={ReactRouterLink} to="/home">
          Home
        </ChakraLink>
      </Box>

      <Box h="50px" bg="white">
        <Select>
          {/* <SelectContainer>
            <SelectInputValue /> // handles current state, and search state
          </SelectContainer>
          <SelectList>
            <SelectOption label="Green" value="green" />
            <SelectOption label="Red" value="red" />
            <SelectOption label="Yellow" value="yellow" />
            <SelectOption label="Blue" value="blue" />
            <SelectOption label="Black" value="black" />
          </SelectList> */}
        </Select>
      </Box>
    </>
  )
}
