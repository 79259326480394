export const Table = {
  variants: {
    simple: {
      // table: {
      // bg: 'mainGray',
      // borderRadius: '10px',
      // borderColor: 'highliteLineGray',
      // borderWidth: '1px',
      // borderStyle: 'solid',
      // maxWidth: '50%',
      // m: '10px',
      // },
      th: {
        fontSize: 'md',
        fontWeight: 'bold',
        color: 'textGreyLight',
        borderBottom: '1px',
        borderColor: 'highliteLineGray',
        px: 2,
        textTransform: 'none',
      },
      tr: {
        '&:last-of-type': {
          td: {
            borderColor: 'transparent',
          },
        },
        '&.borderLess': {
          td: {
            borderColor: 'transparent',
          },
        },
      },
      td: {
        color: 'white',
        borderBottom: '1px',
        borderColor: 'highliteLineGray',
        '&.noPadding': {
          paddingTop: 0,
          paddingBottom: 0,
        },
        px: 2,
        py: 3,
      },
    },
  },
}
