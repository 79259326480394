import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
// import LanguageDetector from 'i18next-browser-languagedetector'
// import { i18nextPlugin } from 'translation-check'

// Importing translation files

import translationEN from './locales/en/translation.json'
import translationDE from './locales/de/translation.json'
import translationRU from './locales/ru/translation.json'
import translationUK from './locales/uk/translation.json'

//Creating object with the variables of imported translation files
export const resources = {
  en: {
    translation: translationEN,
    code: 'en-US',
    name: 'English',
    english_name: 'English',
    name_short: 'EN',
  },
  de: {
    translation: translationDE,
    code: 'de-DE',
    name: 'Deutsch',
    english_name: 'German',
    name_short: 'DE',
  },
  ru: {
    translation: translationRU,
    code: 'ru-RU',
    name: 'Русский',
    english_name: 'Russian',
    name_short: 'RU',
  },
  uk: {
    translation: translationUK,
    code: 'uk-UA',
    name: 'Українська',
    english_name: 'Ukrainian',
    name_short: 'UA',
  },
}

//i18N Initialization

i18n
  .use(Backend)
  // .use(LanguageDetector)
  // .use(
  //   new LanguageDetector(null, {
  //     order: ['querystring', 'cookie', 'localStorage', 'navigator'], // Order of language detection
  //     lookupQuerystring: 'lng', // Querystring parameter to use for language
  //     lookupCookie: 'i18next', // Name of the cookie
  //     lookupLocalStorage: 'i18nextLng_my', // Key in local storage
  //     caches: ['localStorage', 'cookie'], // Caching options
  //   })
  // )
  // .use(i18nextPlugin) // translation-check http://localhost:3000?showtranslations
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    // lookupLocalStorage: 'lang', // Key in local storage
    // supportedLngs: ['en', 'de', 'ru', 'uk'], // Languages you support
    // debug: true,
    // detection: {
    //   order: [
    //     'queryString',
    //     'cookie',
    //     'localStorage',
    //     'sessionStorage',
    //     'navigator',
    //     'htmlTag',
    //     'path',
    //     'subdomain',
    //   ],
    // },
    // backend: {
    //   projectId: 'd59e3715-c422-4808-9f0e-804257b71fef',
    //   apiKey: 'c7a8189f-75bd-42f7-8a66-28b5fceca3d8',
    //   referenceLng: 'en',
    // },

    // backend: {
    //   loadPath: '/locales/{{lng}}/translation.json',
    // },
    // lng: 'en', //default language // if you're using a language detector, do not define the lng option
    keySeparator: false, // we do not use keys in form messages.welcome
    // interpolation: {
    //   escapeValue: false,
    // },
  })

export default i18n

// translation-check
// https://github.com/locize/translation-check?tab=readme-ov-file

// {t('')}
// "":"",

// import { useTranslation } from 'react-i18next'
// const { t, i18n } = useTranslation()
// const currentLanguageCode = i18n.language.split('-')[0]
// const genresMovieCurrentLang = genresMovie[currentLanguageCode]
