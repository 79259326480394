import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { map, findIndex, partition, isEmpty } from 'lodash'

import {
  Button,
  Box,
  Heading,
  VStack,
  SimpleGrid,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react'

import { UnknownWordsList } from 'components/Tables/UnknownWordsList'
import { useMarkMyWordsAsKnownMutation } from 'shared/mutations/userWord'
import { TRANSLATED_SUB_COLOR } from 'helpers'

export const UnknownWords = ({ unknownWordsData, subtitleText, selectedFile }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const { mutate: markMyWordsAsKnown } = useMarkMyWordsAsKnownMutation()

  const { unknownWords, allWordsCount, unknownWordsCount } = unknownWordsData

  const [wordsList, setWordsList] = useState(unknownWords)
  const [translateBtnShow, setTranslateBtnShow] = useState(!isEmpty(wordsList))
  const [unknownWordsListBtnShow, setUnknownWordsListBtnShow] = useState(!isEmpty(wordsList))
  const [translatedSubtitleText, setTranslatedSubtitleText] = useState('')

  const isBaseBreakpoint = useBreakpointValue({ base: true, xl: false })

  // Mark word as known
  const handleSwitchChange = (id) => {
    let foundWordIndex = findIndex(wordsList, { id: id })
    if (foundWordIndex !== -1) {
      wordsList[foundWordIndex].known = !wordsList[foundWordIndex].known
    }
    setWordsList(wordsList)
  }

  // Filter known words and send them to BD for marking as KNOWN
  const filterKnownWords = (words) => {
    const [knownWords, unknownWords] = partition(words, { known: true })

    const knownWordIds = map(knownWords, 'id')

    if (knownWordIds.length > 0) {
      markMyWordsAsKnown(
        {
          ids: knownWordIds,
        },
        {
          onSuccess: () => {
            toast({
              title: t('Known words recorded'),
              position: 'top-right',
              status: 'success',
              duration: 2000,
              isClosable: true,
            })
          },
        }
      )
    }
    return unknownWords
  }

  // Replace words in the subtitleText
  const replaceWordsInSubtitle = (subtitleText, unknownWords) => {
    unknownWords.forEach((word) => {
      const originalWord = word.original
      const translation = word.translates[0]
      const replacement = `<font color="${TRANSLATED_SUB_COLOR}">${originalWord} - ${translation}</font>`

      const regex = new RegExp(`\\b${originalWord}\\b`, 'gi')
      subtitleText = subtitleText.replace(regex, replacement)
    })

    return subtitleText
  }

  const handleTranslateSubtitles = () => {
    // Filter known words and send them to BD for marking as KNOWN
    const unknownWords = filterKnownWords(wordsList)

    // Replace words in the subtitleText
    const updatedSubtitleText = replaceWordsInSubtitle(subtitleText, unknownWords)
    setTranslatedSubtitleText(updatedSubtitleText)

    // Hide unknown words tables and show download BTN
    setUnknownWordsListBtnShow(false)
    setTranslateBtnShow(false)
  }

  const handleDownloadSubtitles = () => {
    const originalFileName = selectedFile.name

    // Create a Blob with the translated text
    const blob = new Blob([translatedSubtitleText], { type: 'text/plain' })

    // Create a URL for the Blob
    const fileURL = URL.createObjectURL(blob)

    // Modify the original file name to include '_translated' before the extension
    const fileNameParts = originalFileName.split('.')
    const fileNameWithoutExtension = fileNameParts.slice(0, -1).join('.')
    const fileExtension = fileNameParts.pop()
    const newFileName = `${fileNameWithoutExtension}_translated.${fileExtension}`

    // Create a temporary anchor element to trigger the download
    const a = document.createElement('a')
    a.href = fileURL
    a.download = newFileName // Set the file name for the download
    document.body.appendChild(a) // Append the anchor to the body
    a.click() // Trigger the download

    // Clean up by removing the anchor element and revoking the Blob URL
    document.body.removeChild(a)
    URL.revokeObjectURL(fileURL)
  }

  // Calculate the word halves only when the wordsList changes to draw 2 tables on big screen
  const [firstHalf, secondHalf] = useMemo(() => {
    const middleIndex = Math.ceil(wordsList?.length / 2)
    return [wordsList?.slice(0, middleIndex), wordsList?.slice(middleIndex)]
  }, [wordsList])

  return (
    <Box layerStyle="outline" color="white">
      <VStack spacing="16px">
        {unknownWordsListBtnShow ? (
          <Heading as="h2" size="lg" textAlign="center">
            <Heading as="span" size="lg" color="teal">
              {unknownWordsCount} / {allWordsCount}
            </Heading>{' '}
            {t(`Unknown words in subtitles`)}
          </Heading>
        ) : (
          <Heading as="h2" size="lg" textAlign="center">
            {t(`Subtitles prepared for You`)}
          </Heading>
        )}

        {unknownWordsListBtnShow &&
          (isBaseBreakpoint ? (
            <UnknownWordsList words={wordsList} onSwitchChange={handleSwitchChange} />
          ) : (
            <SimpleGrid columns={2} spacing="24px" alignSelf="stretch">
              <UnknownWordsList words={firstHalf} onSwitchChange={handleSwitchChange} />
              <UnknownWordsList words={secondHalf} onSwitchChange={handleSwitchChange} />
            </SimpleGrid>
          ))}

        {isEmpty(wordsList) && (
          <Heading as="h2" size="lg" textAlign="center" py="16px">
            {t(`Wow, you know all the words in these subtitles.`)}
          </Heading>
        )}

        {translateBtnShow ? (
          <Button size="lg" onClick={handleTranslateSubtitles}>
            {t(`Translate my subtitles`)}
          </Button>
        ) : (
          <Button size="lg" onClick={handleDownloadSubtitles}>
            {t(`Download translated subtitles`)}
          </Button>
        )}
      </VStack>
    </Box>
  )
}
