import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex, Image, IconButton } from '@chakra-ui/react'

import { ArrowDownIcon } from '@chakra-ui/icons'

import { APP_PATHS } from 'paths'

import { Header } from 'components/PageElements/Header'
import { Footer } from 'components/PageElements/Footer'
import { setDocumentTitle } from 'components/PageElements/helpers'
import { useUserContextState } from 'shared/contexts/user-context-provider'

export const HomePage = () => {
  const navigate = useNavigate()
  setDocumentTitle('')
  const [isVisible, setIsVisible] = useState(false)
  const { user } = useUserContextState()

  useEffect(() => {
    if (user.isLanguagesSet) {
      navigate(APP_PATHS.movies)
    }
  }, [user])

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 10) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener('scroll', toggleVisibility)

    return () => {
      window.removeEventListener('scroll', toggleVisibility)
    }
  }, [])

  const scrollToBottom = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth',
    })
  }

  return (
    <Flex direction="column" minH="100vh" bgColor="mainPageBlack">
      <Header />

      <Image mt="-100px" src="/img/background.jpg" alt="Background" fit="cover" h="100vh" />
      {!isVisible && (
        <IconButton
          position="fixed"
          bottom="20px"
          left={`calc(50% - 24px)`}
          zIndex="999"
          size="lg"
          colorScheme="teal"
          aria-label="Scroll down"
          icon={<ArrowDownIcon />}
          onClick={scrollToBottom}
        />
      )}
      <Footer />
    </Flex>
  )
}
