import React from 'react'
import {
  Heading,
  Box,
  Textarea,
  Image,
  Link,
  Text,
  Switch,
  Badge,
  Stack,
} from '@chakra-ui/react'

export const FilmCard = ({ isLoading, film, onItemClick }) => ( // eslint-disable-line
  <Box bg="gray.22" borderRadius="12px" onClick={onItemClick} cursor={'pointer'}>
    <Box>
      <Heading size="md">{film?.title}</Heading>
    </Box>
    <Box>
      <Textarea isDisabled placeholder={film?.description} />
    </Box>
    <Box>
      <Image src={film?.imageUrl} />
    </Box>
    <Box>
      <Link href={film?.linkUrl} isExternal>
        {/*{link}*/}
      </Link>
    </Box>
    <Box>
      <Text>{film?.wordsCount}</Text>
    </Box>
    <Box>
      <Switch id="isSeries" isDisabled defaultChecked={film?.isSeries} />
    </Box>
    <Box>
      <Text>{film?.season}</Text>
    </Box>
    <Box>
      <Text>{film?.episode}</Text>
    </Box>
    <Box>
      <Badge>{film?.original}</Badge>
    </Box>
    <Box>
      <Stack direction="row">
        <Text>{film?.languages}</Text>
      </Stack>
    </Box>
    <Box>
      <Stack direction="row">
        <Text>{film?.wordIds}</Text>
      </Stack>
    </Box>
  </Box>
)
