import React from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Button, InputGroup, Input, InputRightAddon } from '@chakra-ui/react'
import { Search2Icon } from '@chakra-ui/icons'

export const SearchSubtitle = () => {
  const { t } = useTranslation()

  return (
    <Flex layerStyle="outline" py="16px" px="20px" alignContent="center" justifyContent="center">
      <InputGroup size="lg" h="48px" p="0 8px" maxW="1000px">
        <Input
          placeholder={t('Enter Film or TV Series name')}
          variant="field"
          border="3px solid"
          borderColor="tealButton"
        />
        <InputRightAddon border="3px solid" borderColor="tealButton" borderLeft="0">
          <Button variant="link">
            <Search2Icon boxSize={6} color="tealButton" />
          </Button>
        </InputRightAddon>
      </InputGroup>
    </Flex>
  )
}
