import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { APP_PATHS } from 'paths'
import styles from 'styles/home.module.css'

import { FilmsList } from '../components/PageElements/filmsList'

export const FilmsRecommendationPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const auth = localStorage.getItem('AUTH_TOKEN')

    if (!auth) {
      navigate(APP_PATHS.home)
    }
  }, [navigate])

  return (
    <>
      <div className={styles.filmsRecommendation}>
        <FilmsList />
      </div>
    </>
  )
}
