import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'
import {
  FormControl,
  Input,
  FormErrorMessage,
  Checkbox,
  Text,
  Link,
  HStack,
  Select,
} from '@chakra-ui/react'

import { languagesList, languageLevel } from '../../helpers/LanguageSelectionData'

export const FormField = ({ name, placeholder, type, minH = '64px', url = '#', ...props }) => {
  const { t } = useTranslation()
  const isCheckbox = type === 'checkbox'

  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[name] && form.touched[name]} minH={minH}>
          {!isCheckbox ? (
            <Input
              size="sm"
              variant="outline"
              {...field}
              id={name}
              placeholder={t(placeholder)}
              type={type}
              {...props}
            />
          ) : (
            <HStack>
              <Checkbox {...field} color="white" colorScheme="teal" />
              <Text>{t(placeholder)}</Text>
              <Link
                as={ReactRouterLink}
                variant="paragraph"
                color="teal.500"
                isExternal
                to={url}
              >
                {t(props.linkText)}
              </Link>
            </HStack>
          )}

          <FormErrorMessage mt="4px">{t(form.errors[name])}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  )
}

export const FormFieldSelect = ({ name, placeholder, dataList, ...props }) => {
  const { t } = useTranslation()

  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[name] && form.touched[name]}>
          {/*  */}
          <Select size="md" name={name} placeholder={t(placeholder)} {...field} {...props}>
            {dataList?.map((item, index) => (
              <option key={index} value={item}>
                {name === 'knownLanguage' && languagesList[item].english_name}
                {name === 'languageToLearn' && languagesList[item].english_name}
                {name === 'languageLevel' && languageLevel[item].english_name}
              </option>
            ))}
          </Select>
          <FormErrorMessage mt="4px">{t(form.errors[name])}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  )
}

export const FormFieldSelectSetup = ({ name, placeholder, children, ...props }) => {
  const { t } = useTranslation()

  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[name] && form.touched[name]}>
          <Select size="sm" name={name} placeholder={t(placeholder)} {...field} {...props}>
            {children}
          </Select>
          <FormErrorMessage mt="4px">{t(form.errors[name])}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  )
}
