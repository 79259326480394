import { useQuery } from 'react-query'
import { useToast } from '@chakra-ui/react'
import axios from 'shared/api/setup'
import { QueriesKeysEnum } from './queries-keys-enum'

export const useMyStatistics = (params = {}, enabled = true) => {
  const toast = useToast()

  return useQuery(
    [QueriesKeysEnum.statistics, params],
    () => axios.get('/myStatistics', { params }),
    {
      retry: false,
      enabled,
      onError: (error) => {
        toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
      },
    }
  )
}
