import { extendTheme } from '@chakra-ui/react'
// import { Avatar } from "./avatar";
import { Badge } from './badge'
import { Button } from './button'
// import { Box } from './box'
// import { Checkbox } from "./checkbox";
import { CloseButton } from './close-button'
import { colors } from './colors'
// import { FormError } from "./form-error";
// import { FormLabel } from "./form-label";
import { Input } from './input'
import { Menu } from './menu'
import { Modal } from './modal'
// import { Popover } from "./popover";
// import { Radio } from "./radio";
import { Slider } from './slider'
import { Select } from './select'
import { Table } from './table'
import { Tabs } from './tabs'
import { Text } from './text'
// import { Textarea } from "./textarea";
import { Tooltip } from './tooltip'
import { Link } from './link'
import { Switch } from './switcher'
import { List } from './list'

import '@fontsource-variable/inter/index.css'

export const theme = extendTheme({
  fonts: {
    heading: `'Inter Variable', sans-serif`,
    body: `'Inter Variable', sans-serif`,
  },
  styles: {
    global: {
      'html, body': {
        bg: 'mainBlack',
        color: 'mainGray',
        fontFamily: `'Inter Variable', sans-serif`,
      },
      // "::-webkit-calendar-picker-indicator": {
      //   filter: "invert(1)",
      // },
      // h1: {
      //   fontSize: "28px",
      //   fontWeight: "700",
      //   lineHeight: "35.42px",
      // },
      // h2: {
      //   fontSize: "22px",
      //   fontWeight: "700",
      //   lineHeight: "27.83px",
      // },
      // h3: {
      //   fontSize: "18px",
      //   fontWeight: "700",
      //   lineHeight: "22.77px",
      // },
      // h4: {
      //   fontSize: "16px",
      //   fontWeight: "700",
      //   lineHeight: "20.24px",
      // },
      // h5: {
      //   fontSize: "16px",
      //   fontWeight: "500",
      //   lineHeight: "20.24px",
      // },
      // h6: {
      //   fontSize: "16px",
      //   fontWeight: "400",
      //   lineHeight: "20.24px",
      // },
    },
  },
  colors,
  // sizes: {
  //   "4xl": "930px",
  //   container: {
  //     lg: "calc(930px + 2rem)",
  //     xl: "calc(1300px + 2rem)",
  //   },
  // },
  layerStyles: {
    outline: {
      border: '2px solid',
      borderColor: 'highliteLineGray',
      borderRadius: '24px',
      alignSelf: 'stretch',
      paddingX: '20px',
      paddingY: '16px',
    },
  },
  components: {
    Button,
    //   Checkbox,
    //   Radio,
    Tooltip,
    Text,
    //   Avatar,
    Input,
    //   Textarea,
    Slider,
    Select,

    Switch,
    Modal,
    Menu,
    CloseButton,
    //   FormLabel,
    //   FormError,
    Tabs,
    Table,
    Badge,
    //   Popover,
    Link,
    List,
  },
})
