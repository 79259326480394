import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'

import { APP_PATHS } from 'paths'
import { FormFieldSelect } from './FormField'
import { useSetVocabularyMutation } from 'shared/mutations/userProfile'
import { useIndexVocabularies } from 'shared/queries/vocabulary'
import { useUserContextStateDispatch } from 'shared/contexts/user-context-provider'

export const LanguageSelectionModal = ({
  isLanguageSelectionOpen,
  onLanguageSelectionClose,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { mutate: onSetVocabularyMutation } = useSetVocabularyMutation()
  const { data: vocabularies, isLoading: isVocabulariesLoading } = useIndexVocabularies()
  const { onLogout, updateUserContext } = useUserContextStateDispatch()

  const LanguageSelectionSchema = Yup.object().shape({
    knownLanguage: Yup.string().required(t(`Please select the language you know`)),
    languageToLearn: Yup.string()
      .notOneOf([Yup.ref('knownLanguage'), null], t(`Language can't be the same`))
      .required(t(`Please select the language you want to learn`)),
    languageLevel: Yup.string().required(t(`Please select your language level`)),
  })

  const mapKnownLanguage = [...new Set(vocabularies?.map((item) => item.to))]

  const mapLanguageToLearn = (userLang) => {
    return [
      ...new Set(vocabularies?.filter((item) => item.to === userLang)?.map((item) => item.from)),
    ]
  }

  const mapLanguageLevel = (userLang, studLang) => {
    return [
      ...new Set(
        vocabularies
          ?.filter((item) => item.to === userLang && item.from === studLang)
          ?.map((item) => item.level)
      ),
    ]
  }

  const mapVocabularyId = (values) => {
    const id = vocabularies
      ?.filter(
        (item) =>
          item.to === values.knownLanguage &&
          item.from === values.languageToLearn &&
          item.level === values.languageLevel
      )
      ?.map((item) => item.id)[0]
    return id
  }

  const handleSetVocabulary = async (values) => {
    const data = { vocabularyId: mapVocabularyId(values) }

    onSetVocabularyMutation(data, {
      onSuccess: (user) => {
        updateUserContext({
          type: 'update',
          payload: {
            user,
          },
        })
        navigate(APP_PATHS.movies)
      },
    })
  }

  const languageSelectionCanceled = () => {
    onLogout()
    onLanguageSelectionClose()
  }

  if (isVocabulariesLoading) {
    return null
  }

  return (
    <Modal isOpen={isLanguageSelectionOpen} onClose={languageSelectionCanceled} isCentered>
      <ModalOverlay />
      <ModalContent maxW="350px">
        <ModalHeader>{t(`Language selection`)}</ModalHeader>
        <Formik
          initialValues={{
            knownLanguage: '',
            languageToLearn: '',
            languageLevel: '',
          }}
          validationSchema={LanguageSelectionSchema}
          onSubmit={(values) => {
            handleSetVocabulary(values)
          }}
        >
          {({ values }) => (
            <Form>
              <ModalBody>
                <VStack spacing="12px">
                  <FormFieldSelect
                    name="knownLanguage"
                    placeholder={t(`Select language you know`)}
                    dataList={mapKnownLanguage}
                  />
                  <FormFieldSelect
                    name="languageToLearn"
                    placeholder={t(`Select language to learn`)}
                    dataList={mapLanguageToLearn(values?.knownLanguage)}
                    isDisabled={isEmpty(mapLanguageToLearn(values?.knownLanguage))}
                  />
                  <FormFieldSelect
                    name="languageLevel"
                    placeholder={t(`Select your language level`)}
                    dataList={mapLanguageLevel(values?.knownLanguage, values?.languageToLearn)}
                    isDisabled={isEmpty(
                      mapLanguageLevel(values?.knownLanguage, values?.languageToLearn)
                    )}
                  />

                  <Text variant="small" color="textGrey" textAlign="center">
                    {t(
                      `* This window is displayed at the first login. You can change settings in the profile settings.`
                    )}
                  </Text>
                </VStack>
              </ModalBody>

              <ModalFooter>
                <Button size="lg" colorScheme="teal" type="submit">
                  {t(`Let's get started`)}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  )
}
