export const Input = {
  baseStyle: {
    color: 'textGrey',
    field: {
      // color: 'textGrey',
      // background: 'white',
      _placeholder: {
        color: 'textGrey',
      },
      _invalid: {
        color: 'warning',
      },
      _active: {
        borderColor: 'tealButton',
        boxShadow: `0 0 0 1px #38B2AC`,
      },
      _focus: {
        outline: '3px',
        color: 'textGrey',
        // borderColor: 'tealButton',
        // boxShadow: `0 0 0 1px tealButton`,
        // outlineColor: 'tealButton',
        background: 'white',
        _invalid: {
          borderColor: 'warning',
          boxShadow: `0 0 0 1px #EB5757`,
          outlineColor: 'warning',
        },
      },
    },
  },
  sizes: {
    sm: {
      field: {
        fontSize: 'md',
        h: '36px',
        // lineHeight: '26.5px',
        py: '8px',
        px: '12px',
      },
    },
    md: {
      field: {
        fontSize: 'md',
        h: '69px',
        lineHeight: '26.5px',
        py: '21px',
        px: '25px',
      },
    },
  },
  variants: {
    outline: {
      field: {
        borderRadius: '4px',
        borderColor: 'highliteLineGray',
        background: 'white',
        _hover: {
          borderColor: 'tealButton',
        },
      },
    },
    file: {
      field: {
        border: '2px solid',
        borderRadius: '6px',
        borderColor: 'tealButton',
        background: 'white',
        _hover: {
          borderColor: 'tealButton',
        },
      },
      addon: {
        border: '2px solid',
        borderColor: 'tealButton',
        background: 'white',
        borderRadius: '6px',
        color: 'tealButton',
        _hover: {
          svg: {
            transform: 'scale(1.2)',
          },
        },
        _active: {
          color: 'white',
          background: 'tealButton',
        },
      },
    },
  },
  defaultProps: {
    size: 'sm',
  },
}
