// import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useToast } from '@chakra-ui/react'
import axios from 'shared/api/setup'

export const useMarkMyWordsAsKnownMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.put('/markMyWordsAsKnown', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useMarkMyWordsAsCorrectResponseMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.put('/markMyWordsAsCorrectResponse', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useMarkMyWordsAsIncorrectResponseMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.put('/markMyWordsAsIncorrectResponse', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useAnalyzeWordsMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/analyzeWords', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}
