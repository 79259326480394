import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Spinner, Text } from '@chakra-ui/react'

export const Loading = () => {
  const { t } = useTranslation()
  return (
    <Flex layerStyle="outline" alignItems="center" justify="center" p={0} minH="100px">
      <Text fontSize="36px" textAlign="center">
        <Spinner size="lg" color="teal.500" /> {t(`Loading...`)}
      </Text>
    </Flex>
  )
}
