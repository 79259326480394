import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Stack, SimpleGrid, Skeleton, Flex, Button } from '@chakra-ui/react'

import { Search } from '../../components/Search/'
import { CardVideoBase } from '../../components/Cards/CardVideoBase'
import { setDocumentTitle } from 'components/PageElements/helpers'

import { useIndexMyRecommendedFilms } from 'shared/queries/film'
import { useUserContextState } from 'shared/contexts/user-context-provider'
import { COUNT_CARDS_ON_PAGE_FOR_PAGINATION } from 'helpers'

export const MoviesPage = () => {
  const { t } = useTranslation()
  setDocumentTitle(t('Movies'))

  const [currentPage, setCurrentPage] = useState(1)

  const { user } = useUserContextState()
  const currentLearningLanguage = user?.currentLearningLanguage

  // TODO For filter,...
  const { data: indexMyRecommendedFilms, isLoading: isMyRecommendedFilmsLoading } =
    useIndexMyRecommendedFilms({
      original: currentLearningLanguage,
    })

  // Calculate the total pages
  const totalPages = Math.ceil(
    indexMyRecommendedFilms?.length / COUNT_CARDS_ON_PAGE_FOR_PAGINATION
  )

  // Calculate the cards to show on the current page
  const indexOfLastCard = currentPage * COUNT_CARDS_ON_PAGE_FOR_PAGINATION
  const indexOfFirstCard = indexOfLastCard - COUNT_CARDS_ON_PAGE_FOR_PAGINATION
  const currentCards = indexMyRecommendedFilms?.slice(indexOfFirstCard, indexOfLastCard)
  // Change page handler
  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  // if (isMyRecommendedFilmsLoading) {
  //   return null
  // }

  return (
    <Stack
      paddingX="16px"
      justify="flex-start"
      align="center"
      spacing="28px"
      overflow="hidden"
      alignSelf="stretch"
    >
      <Search />
      <SimpleGrid
        layerStyle="outline"
        paddingX={{ base: '24px', sm: '30px', md: '40px' }}
        paddingY={{ base: '24px', sm: '40px' }}
        justifyItems="center"
        spacing="20px"
        minChildWidth="250px"
      >
        {isMyRecommendedFilmsLoading &&
          Array.from({ length: 8 }, (_, index) => (
            <Skeleton key={index} h="480px" w="250px" borderRadius="20px" />
          ))}
        {currentCards?.map((movie) => (
          <CardVideoBase
            key={movie.id}
            id={movie.id}
            href={`/movies/${movie.id}`}
            imageUrl={movie.imageUrl}
            title={movie.title}
            tmdbRating={Math.round(movie.rating)}
            knownWordsPercentage={Math.round(movie.knownWordsPercentage)}
            knownWordsCount={movie.knownWordsCount}
            wordsCount={movie.wordsCount}
            createdAt={movie.createdAt}
            duration={movie.duration}
          />
        ))}
      </SimpleGrid>

      {totalPages > 1 && (
        <Flex justify="center" mt="4">
          <Button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
            Prev
          </Button>
          {Array.from({ length: totalPages }, (_, i) => (
            <Button
              key={i + 1}
              onClick={() => paginate(i + 1)}
              mx="1"
              colorScheme="teal"
              variant={currentPage === i + 1 ? 'solid' : 'ghost'}
            >
              {i + 1}
            </Button>
          ))}
          <Button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </Flex>
      )}
    </Stack>
  )
}
