import React from 'react'
import { useTranslation } from 'react-i18next'

import { Collapse, VStack, useDisclosure } from '@chakra-ui/react'

import { useUserContextState } from 'shared/contexts/user-context-provider'

import { Statistics } from '../components/Modules/statistics'
import { Study } from '../components/Modules/study'
import { setDocumentTitle } from 'components/PageElements/helpers'

export const StudyPage = () => {
  const { t } = useTranslation()
  const { user } = useUserContextState()

  setDocumentTitle(t('LL QUIZ'))

  const { isOpen, onToggle } = useDisclosure(true)

  return (
    <VStack spacing="24px">
      <Collapse in={!isOpen} animateOpacity>
        <Statistics />
      </Collapse>
      <Study isOpen={isOpen} onToggle={onToggle} user={user} />
    </VStack>
  )
}
