import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  useToast,
} from '@chakra-ui/react'

import { languagesList } from 'helpers'

import { useUpdateMyUserProfileMutation } from 'shared/mutations/userProfile'
import { useUserContextStateDispatch } from 'shared/contexts/user-context-provider'

export const MyLearningLanguages = ({ user, refetchUserProfile }) => {
  const { t } = useTranslation()
  const toast = useToast()

  const { mutate: updateMyUserProfile } = useUpdateMyUserProfileMutation()
  const { updateUserContext } = useUserContextStateDispatch()

  const handleUpdateLearningLanguage = (values) => {
    const lang = { currentLearningLanguage: values }

    updateMyUserProfile(lang, {
      onSuccess: () => {
        updateUserContext({
          type: 'update',
          payload: { user: { ...user, ...lang } },
        })
        refetchUserProfile()
        toast({
          position: 'top-right',
          status: 'success',
          title: t(`Learning language updated`),
          isClosable: true,
        })
      },
    })
  }

  return (
    <TableContainer
      border="1px"
      borderRadius="10px"
      borderColor="highliteLineGray"
      bg="mainGray"
      p="12px"
      my="8px"
      alignSelf="stretch"
    >
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th textAlign="center" whiteSpace="break-spaces">
              {t('Studying language')}
            </Th>
            <Th textAlign="center" whiteSpace="break-spaces">
              {t('Change language')}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {user?.learningLanguages?.map((language, index) => (
            <Tr key={index} borderBottom="1px" borderColor="highlightLineGray">
              <Td textAlign="center">{languagesList[language].english_name}</Td>
              <Td textAlign="center">
                <Button
                  isDisabled={language === user.currentLearningLanguage}
                  onClick={() => handleUpdateLearningLanguage(language)}
                >
                  {t(`Switch`)}
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
