//https://developer.themoviedb.org/reference/configuration-languages
//https://developer.themoviedb.org/reference/genre-movie-list

export const genresMovie = {
  en: [
    {
      id: 28,
      name: 'Action',
    },
    {
      id: 12,
      name: 'Adventure',
    },
    {
      id: 16,
      name: 'Animation',
    },
    {
      id: 35,
      name: 'Comedy',
    },
    {
      id: 80,
      name: 'Crime',
    },
    {
      id: 99,
      name: 'Documentary',
    },
    {
      id: 18,
      name: 'Drama',
    },
    {
      id: 10751,
      name: 'Family',
    },
    {
      id: 14,
      name: 'Fantasy',
    },
    {
      id: 36,
      name: 'History',
    },
    {
      id: 27,
      name: 'Horror',
    },
    {
      id: 10402,
      name: 'Music',
    },
    {
      id: 9648,
      name: 'Mystery',
    },
    {
      id: 10749,
      name: 'Romance',
    },
    {
      id: 878,
      name: 'Science Fiction',
    },
    {
      id: 10770,
      name: 'TV Movie',
    },
    {
      id: 53,
      name: 'Thriller',
    },
    {
      id: 10752,
      name: 'War',
    },
    {
      id: 37,
      name: 'Western',
    },
  ],

  de: [
    {
      id: 28,
      name: 'Action',
    },
    {
      id: 12,
      name: 'Abenteuer',
    },
    {
      id: 16,
      name: 'Animation',
    },
    {
      id: 35,
      name: 'Komödie',
    },
    {
      id: 80,
      name: 'Krimi',
    },
    {
      id: 99,
      name: 'Dokumentarfilm',
    },
    {
      id: 18,
      name: 'Drama',
    },
    {
      id: 10751,
      name: 'Familie',
    },
    {
      id: 14,
      name: 'Fantasy',
    },
    {
      id: 36,
      name: 'Historie',
    },
    {
      id: 27,
      name: 'Horror',
    },
    {
      id: 10402,
      name: 'Musik',
    },
    {
      id: 9648,
      name: 'Mystery',
    },
    {
      id: 10749,
      name: 'Liebesfilm',
    },
    {
      id: 878,
      name: 'Science Fiction',
    },
    {
      id: 10770,
      name: 'TV-Film',
    },
    {
      id: 53,
      name: 'Thriller',
    },
    {
      id: 10752,
      name: 'Kriegsfilm',
    },
    {
      id: 37,
      name: 'Western',
    },
  ],

  ru: [
    {
      id: 28,
      name: 'боевик',
    },
    {
      id: 12,
      name: 'приключения',
    },
    {
      id: 16,
      name: 'мультфильм',
    },
    {
      id: 35,
      name: 'комедия',
    },
    {
      id: 80,
      name: 'криминал',
    },
    {
      id: 99,
      name: 'документальный',
    },
    {
      id: 18,
      name: 'драма',
    },
    {
      id: 10751,
      name: 'семейный',
    },
    {
      id: 14,
      name: 'фэнтези',
    },
    {
      id: 36,
      name: 'история',
    },
    {
      id: 27,
      name: 'ужасы',
    },
    {
      id: 10402,
      name: 'музыка',
    },
    {
      id: 9648,
      name: 'детектив',
    },
    {
      id: 10749,
      name: 'мелодрама',
    },
    {
      id: 878,
      name: 'фантастика',
    },
    {
      id: 10770,
      name: 'телевизионный фильм',
    },
    {
      id: 53,
      name: 'триллер',
    },
    {
      id: 10752,
      name: 'военный',
    },
    {
      id: 37,
      name: 'вестерн',
    },
  ],

  uk: [
    {
      id: 28,
      name: 'Бойовик',
    },
    {
      id: 12,
      name: 'Пригоди',
    },
    {
      id: 16,
      name: 'Мультфільм',
    },
    {
      id: 35,
      name: 'Комедія',
    },
    {
      id: 80,
      name: 'Кримінал',
    },
    {
      id: 99,
      name: 'Документальний',
    },
    {
      id: 18,
      name: 'Драма',
    },
    {
      id: 10751,
      name: 'Сімейний',
    },
    {
      id: 14,
      name: 'Фентезі',
    },
    {
      id: 36,
      name: 'Історичний',
    },
    {
      id: 27,
      name: 'Жахи',
    },
    {
      id: 10402,
      name: 'Музика',
    },
    {
      id: 9648,
      name: 'Детектив',
    },
    {
      id: 10749,
      name: 'Мелодрама',
    },
    {
      id: 878,
      name: 'Фантастика',
    },
    {
      id: 10770,
      name: 'Телефільм',
    },
    {
      id: 53,
      name: 'Трилер',
    },
    {
      id: 10752,
      name: 'Військовий',
    },
    {
      id: 37,
      name: 'Вестерн',
    },
  ],
}

export const certifications = [
  {
    certification: 'R',
    meaning:
      'Under 17 requires accompanying parent or adult guardian 21 or older. The parent/guardian is required to stay with the child under 17 through the entire movie, even if the parent gives the child/teenager permission to see the film alone. These films may contain strong profanity, graphic sexuality, nudity, strong violence, horror, gore, and strong drug use. A movie rated R for profanity often has more severe or frequent language than the PG-13 rating would permit. An R-rated movie may have more blood, gore, drug use, nudity, or graphic sexuality than a PG-13 movie would admit.',
    order: 4,
  },
  {
    certification: 'PG',
    meaning:
      'Some material may not be suitable for children under 10. These films may contain some mild language, crude/suggestive humor, scary moments and/or violence. No drug content is present. There are a few exceptions to this rule. A few racial insults may also be heard.',
    order: 2,
  },
  {
    certification: 'NC-17',
    meaning:
      'These films contain excessive graphic violence, intense or explicit sex, depraved, abhorrent behavior, explicit drug abuse, strong language, explicit nudity, or any other elements which, at present, most parents would consider too strong and therefore off-limits for viewing by their children and teens. NC-17 does not necessarily mean obscene or pornographic in the oft-accepted or legal meaning of those words.',
    order: 5,
  },
  {
    certification: 'G',
    meaning:
      'All ages admitted. There is no content that would be objectionable to most parents. This is one of only two ratings dating back to 1968 that still exists today.',
    order: 1,
  },
  {
    certification: 'NR',
    meaning: 'No rating information.',
    order: 0,
  },
  {
    certification: 'PG-13',
    meaning:
      'Some material may be inappropriate for children under 13. Films given this rating may contain sexual content, brief or partial nudity, some strong language and innuendo, humor, mature themes, political themes, terror and/or intense action violence. However, bloodshed is rarely present. This is the minimum rating at which drug content is present.',
    order: 3,
  },
]

export const genresTVShows = [
  {
    id: 10759,
    name: 'Action & Adventure',
  },
  {
    id: 16,
    name: 'Animation',
  },
  {
    id: 35,
    name: 'Comedy',
  },
  {
    id: 80,
    name: 'Crime',
  },
  {
    id: 99,
    name: 'Documentary',
  },
  {
    id: 18,
    name: 'Drama',
  },
  {
    id: 10751,
    name: 'Family',
  },
  {
    id: 10762,
    name: 'Kids',
  },
  {
    id: 9648,
    name: 'Mystery',
  },
  {
    id: 10763,
    name: 'News',
  },
  {
    id: 10764,
    name: 'Reality',
  },
  {
    id: 10765,
    name: 'Sci-Fi & Fantasy',
  },
  {
    id: 10766,
    name: 'Soap',
  },
  {
    id: 10767,
    name: 'Talk',
  },
  {
    id: 10768,
    name: 'War & Politics',
  },
  {
    id: 37,
    name: 'Western',
  },
]

export const languages = [
  {
    iso_639_1: 'en',
    english_name: 'English',
    name: 'English',
  },
  {
    iso_639_1: 'de',
    english_name: 'German',
    name: 'Deutsch',
  },
  {
    iso_639_1: 'fr',
    english_name: 'French',
    name: 'Français',
  },
  {
    iso_639_1: 'es',
    english_name: 'Spanish',
    name: 'Español',
  },
  {
    iso_639_1: 'uk',
    english_name: 'Ukrainian',
    name: 'Український',
  },
]

export const years = () => {
  const countOfYears = 3
  const currentYear = new Date().getFullYear()

  const listYears = Array.from({ length: countOfYears }, (v, k) => currentYear - k)

  return listYears
}
