import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Link, Stack } from '@chakra-ui/react'

import TwitterIcon from '@mui/icons-material/Twitter'
import TelegramIcon from '@mui/icons-material/Telegram'
// import InstagramIcon from '@mui/icons-material/Instagram'
// import FacebookIcon from '@mui/icons-material/Facebook'

export const Footer = () => {
  const { t } = useTranslation()
  return (
    <Stack
      as="footer"
      bg="mainGray"
      alignSelf="stretch"
      direction="column"
      justify="center"
      align="center"
      spacing="26px"
      p="26px 0"
    >
      <Stack direction="row" justify="center" align="center" spacing="35px">
        <Link href="https://twitter.com/moviemingle_" isExternal color="teal">
          <TwitterIcon sx={{ fontSize: 30 }} />
        </Link>
        <Link href="https://t.me/MoviesMingleBot" isExternal color="teal">
          <TelegramIcon sx={{ fontSize: 30 }} />
        </Link>
        {/* <Link color="teal" href="#">
          <InstagramIcon sx={{ fontSize: 30 }} />
        </Link>
        <Link color="teal" href="#">
          <FacebookIcon sx={{ fontSize: 30 }} />
        </Link> */}
      </Stack>
      <Link as={ReactRouterLink} fontSize="2xl" color="white" to="/we-do">
        {t('What we do')}
      </Link>
      <Link as={ReactRouterLink} fontSize="md" color="white" to="/terms-and-condition">
        {t('Terms and condition')}
      </Link>
    </Stack>
  )
}
