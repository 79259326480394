import { useMutation } from 'react-query'
import { useToast } from '@chakra-ui/react'
import axios from 'shared/api/setup'

export const useSetVocabularyMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.put('/setVocabulary', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useUpdateMyUserProfileMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.put('/updateMyUserProfile', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}
