import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { map, isEmpty, partition } from 'lodash'

import {
  Box,
  Button,
  Text,
  HStack,
  Flex,
  Spacer,
  Wrap,
  IconButton,
  useToast,
  Link,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react'
import TelegramIcon from '@mui/icons-material/Telegram'

import { APP_PATHS } from 'paths'

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'

import { COUNT_LEARNING_WORDS_PER_SESSION } from 'helpers'
import { QUIZIncorrectWordsList } from 'components/Tables/QUIZIncorrectWordsList'
import { useIndexMyWordsToStudy } from 'shared/queries/userWord'
import {
  useMarkMyWordsAsCorrectResponseMutation,
  useMarkMyWordsAsIncorrectResponseMutation,
} from 'shared/mutations/userWord'

export const Study = ({ onToggle, user }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const navigate = useNavigate()

  const [step, setStep] = useState(0)
  const [currentWordIndex, setCurrentWordIndex] = useState(0)
  const [results, setResults] = useState([])
  const [unknownWordsList, setUnknownWordsList] = useState([])

  const buttonSize = useBreakpointValue({ base: 'md', md: 'lg' })

  const {
    data: indexMyWordsToStudy,
    isLoading: isIndexMyWordsToStudyLoading,
    refetch: refetchWords,
  } = useIndexMyWordsToStudy({
    from: user?.currentLearningLanguage,
    to: user?.knownLanguage,
    limit: COUNT_LEARNING_WORDS_PER_SESSION,
  })

  const { mutate: markMyWordsAsCorrect } = useMarkMyWordsAsCorrectResponseMutation()
  const { mutate: markMarkMyWordsAsIncorrect } = useMarkMyWordsAsIncorrectResponseMutation()

  const noWordsToLearn = isEmpty(indexMyWordsToStudy) && !isIndexMyWordsToStudyLoading

  // Check answer and write to memory
  const handleSubmitAnswer = (translation) => {
    const currentWord = indexMyWordsToStudy[currentWordIndex]

    const isCorrect = currentWord.toOptions[0] === translation
    const newResults = [...results, { ...currentWord, isCorrect }]
    setResults(newResults)

    if (currentWordIndex < indexMyWordsToStudy?.length - 1) {
      setCurrentWordIndex(currentWordIndex + 1)
    } else {
      filterKnownWords(newResults)
      setStep(2) // Move to results step
    }
  }

  // Filter known/unknown words and send them to BD,
  const filterKnownWords = (words) => {
    const [knownWords, unknownWords] = partition(words, { isCorrect: true })

    const knownWordIds = map(knownWords, 'id')
    const unknownWordsIds = map(unknownWords, 'id')

    if (!isEmpty(knownWordIds)) {
      markMyWordsAsCorrect(
        {
          ids: knownWordIds,
        },
        {
          onSuccess: () => {
            toast({
              title: t('Known words recorded'),
              position: 'top-right',
              status: 'success',
              duration: 2000,
              isClosable: true,
            })
          },
        }
      )
    }
    if (!isEmpty(unknownWordsIds)) {
      markMarkMyWordsAsIncorrect(
        {
          ids: unknownWordsIds,
        },
        {
          onSuccess: () => {
            toast({
              title: t('Unknown words recorded'),
              position: 'top-right',
              status: 'success',
              duration: 2000,
              isClosable: true,
            })
          },
        }
      )
    }
    setUnknownWordsList(unknownWords)
  }

  const handleNextStep = () => {
    if (!isIndexMyWordsToStudyLoading) {
      setStep(step + 1)
    }
  }

  return (
    <Flex
      layerStyle="outline"
      bgColor="filtersBackground"
      color="white"
      minH="550px"
      justify="center"
      align="center"
      direction="column"
      position="relative"
    >
      {step === 0 && !noWordsToLearn && (
        <Box position="absolute" top="10px" right="10px">
          <Tooltip
            hasArrow
            label={<Text>{t(`Study in Telegram`)}</Text>}
            placement="bottom-end"
            bg="mainGray"
          >
            <Link href="https://t.me/MoviesMingleBot" isExternal color="teal">
              <TelegramIcon sx={{ fontSize: 45 }} />
            </Link>
          </Tooltip>
        </Box>
      )}

      {noWordsToLearn && (
        <>
          <Spacer />
          <Box>
            <Text as="h3" fontSize="3xl" align="center" mb="32px">
              {t('Congratulations!')}
            </Text>
            <Text fontSize="xl">
              {t(
                `You have learned all the unknown words. Let's choose a new movie or TV series to watch.`
              )}
            </Text>
          </Box>
          <Spacer />
          <HStack>
            <Button
              size="lg"
              fontSize="3xl"
              colorScheme="teal"
              onClick={() => navigate(APP_PATHS.movies)}
            >
              {t('Go to Movies')}
            </Button>
            <Spacer />
            <Button
              size="lg"
              fontSize="3xl"
              colorScheme="teal"
              onClick={() => navigate(APP_PATHS.shows)}
            >
              {t('Go to TV Shows')}
            </Button>
          </HStack>
          <Spacer />
        </>
      )}

      {step === 0 && !noWordsToLearn && (
        <Flex direction="column" flex="1" justify="center" alignItems="center">
          <Text fontSize="3xl" color="white" mb="16px">
            {t(`Let's learn the words`)}
          </Text>
          <IconButton
            variant="link"
            icon={<PlayCircleOutlineIcon style={{ width: '80px', height: '80px' }} />}
            onClick={() => {
              onToggle()
              setTimeout(() => {
                handleNextStep()
              }, 500)
            }}
          />
        </Flex>
      )}
      {step !== 0 && (
        <>
          <Text fontSize="xl" color="white" mb="16px">
            {t(`Let's learn the words`)}
          </Text>

          <Flex direction="column" align="center" flex="1" mb="50px">
            {step === 1 && (
              <>
                <Text fontSize="xl">
                  {currentWordIndex + 1}/{indexMyWordsToStudy?.length}
                </Text>
                <Spacer />
                <Text fontSize="4xl" mb={4}>
                  {indexMyWordsToStudy[currentWordIndex]?.fromOptions}
                </Text>
                <Spacer />
                <Wrap spacing="30px" justify="center">
                  {indexMyWordsToStudy[currentWordIndex]?.options?.map((translation) => (
                    <Button
                      size={buttonSize}
                      fontSize={{ base: 'xl', md: '4xl' }}
                      key={translation}
                      onClick={() => {
                        handleSubmitAnswer(translation)
                      }}
                    >
                      {translation}
                    </Button>
                  ))}
                </Wrap>
              </>
            )}

            {step === 2 && (
              <>
                <Spacer />
                <Text fontSize="2xl" mb="16px">
                  {t('Congratulations!')}
                </Text>
                <Text>{t('You have completed the exercise.')}</Text>
                <Text>
                  {t('Your score')}: {results?.filter((result) => result.isCorrect)?.length}/
                  {indexMyWordsToStudy?.length}
                </Text>
                <Spacer />
                {!isEmpty(unknownWordsList) && (
                  <QUIZIncorrectWordsList words={unknownWordsList} />
                )}
                <Button
                  size={buttonSize}
                  fontSize={{ base: 'xl', md: '3xl' }}
                  onClick={() => {
                    setStep(0)
                    setResults([])
                    setCurrentWordIndex(0)
                    refetchWords()
                    setTimeout(() => {
                      onToggle()
                    }, 200)
                  }}
                >
                  {t('Restart')}
                </Button>
              </>
            )}

            {/* {step === 3 && (
              <>
                <Spacer />
                <Text fontSize="2xl" mb="16px">
                  {t('Congratulations!')}
                </Text>
                <Text>
                  {t(
                    'All the words have been learned for today, so get some rest and come back tomorrow.'
                  )}
                </Text>
                <Text>
                  {t('Your statistics for today')}:
                  {results.filter((result) => result.isCorrect)?.length}/
                  {indexMyWordsToStudy?.length}
                </Text>
                <Spacer />
                <Button size="lg" fontSize="3xl" onClick={() => setStep(4)}>
                  {t('See Results')}
                </Button>
              </>
            )} */}
          </Flex>
        </>
      )}
    </Flex>
  )
}
