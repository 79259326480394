import React from 'react'
import { useTranslation } from 'react-i18next'

import { HStack, Tooltip, Button, SkeletonCircle } from '@chakra-ui/react'
import { Icon } from '@chakra-ui/icons'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import CheckIcon from '@mui/icons-material/Check'

import { useMyUserMovieMutation } from 'shared/mutations/userMovie'

const FavButton = ({ label, btnType, btnIcon, myFilmList, toggleMovieProperty }) => {
  return (
    <Tooltip hasArrow label={label} placement="bottom" bg="mainGray">
      <Button
        boxSize="56px"
        p="0"
        borderRadius="999px"
        onClick={() => toggleMovieProperty(btnType)}
        isActive={myFilmList?.[0]?.[btnType]}
      >
        <Icon as={btnIcon} />
      </Button>
    </Tooltip>
  )
}

export const UserFavorites = ({ movieId, myFilmList, setMyFilmList }) => {
  const { t } = useTranslation()

  const { mutate: updateMyMoviesList } = useMyUserMovieMutation()

  // Add film to isBookmark, isFavorite, isViewed
  const toggleMovieProperty = (property) => {
    const currentStatus = myFilmList?.[0]?.[property]

    updateMyMoviesList(
      {
        filmId: movieId,
        [property]: !currentStatus,
      },
      {
        onSuccess: (values) => {
          setMyFilmList([values])
        },
      }
    )
  }

  if (!movieId || !myFilmList || !setMyFilmList) {
    return (
      <HStack spacing="16px" justifyContent="center">
        <SkeletonCircle size="56px" />
        <SkeletonCircle size="56px" />
        <SkeletonCircle size="56px" />
      </HStack>
    )
  }

  return (
    <HStack spacing="16px" justifyContent="center">
      <FavButton
        label={t('Favorite')}
        btnType={'isFavorite'}
        btnIcon={FavoriteBorderIcon}
        myFilmList={myFilmList}
        toggleMovieProperty={toggleMovieProperty}
      />
      <FavButton
        label={t('Bookmarks')}
        btnType={'isBookmark'}
        btnIcon={BookmarkBorderIcon}
        myFilmList={myFilmList}
        toggleMovieProperty={toggleMovieProperty}
      />
      <FavButton
        label={t('Viewed')}
        btnType={'isViewed'}
        btnIcon={CheckIcon}
        myFilmList={myFilmList}
        toggleMovieProperty={toggleMovieProperty}
      />
    </HStack>
  )
}
