import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Flex, Image, useDisclosure } from '@chakra-ui/react'

import { APP_PATHS } from 'paths'

import { Header } from 'components/PageElements/Header'
import { Footer } from 'components/PageElements/Footer'
import { setDocumentTitle } from 'components/PageElements/helpers'
import { EmailVerificationExpired, EmailWasVerified } from 'components/Modals'
import { useVerifyEmailMutation } from 'shared/mutations/user'

export const ConfirmEmail = () => {
  const navigate = useNavigate()
  setDocumentTitle('Confirm Email')
  const params = useParams()

  const { mutate: verifyEmail } = useVerifyEmailMutation()

  const {
    isOpen: isVerifiedOpen,
    onOpen: onVerifiedOpen,
    onClose: onVerifiedClose,
  } = useDisclosure()

  const {
    isOpen: isResendLinkOpen,
    onOpen: onResendLinkOpen,
    onClose: onResendLinkClose,
  } = useDisclosure()

  useEffect(() => {
    if (params?.token) {
      verifyEmail(params?.token, {
        onError: () => {
          onResendLinkOpen()
        },
        onSuccess: () => {
          onVerifiedOpen()
        },
      })
    }
  }, [onVerifiedOpen])

  const onEmailVerifiedClose = () => {
    onVerifiedClose()
    navigate(APP_PATHS.home)
  }

  const onResendVerificationLinkClose = () => {
    onResendLinkClose()
    navigate(APP_PATHS.home)
  }

  return (
    <Flex direction="column" minH="100vh" bgColor="mainPageBlack">
      <Header />

      <Image mt="-100px" src="/img/background.png" alt="Background" fit="contain" h="100vh" />

      <Footer />
      <EmailWasVerified isOpen={isVerifiedOpen} onClose={onEmailVerifiedClose} />
      <EmailVerificationExpired
        isOpen={isResendLinkOpen}
        onClose={onResendVerificationLinkClose}
      />
    </Flex>
  )
}
