import React, { useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { Button } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import { APP_PATHS } from 'paths'

export const Login = () => {
  const queryCache = useQueryClient()
  const navigate = useNavigate()

  const handleLogout = useCallback(async () => {
    localStorage.removeItem('AUTH_TOKEN')
    await queryCache.invalidateQueries()
    navigate(APP_PATHS.home)
  }, [])

  const handleLogIn = async () => {
    try {
      const authToken = 'mock'
      localStorage.setItem('AUTH_TOKEN', authToken)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div>
      {!localStorage.getItem('AUTH_TOKEN') && (
        <Button size="lg" onClick={handleLogIn}>
          Log in
        </Button>
      )}
      {localStorage.getItem('AUTH_TOKEN') && (
        <Button size="lg" onClick={handleLogout}>
          Log out
        </Button>
      )}
    </div>
  )
}
