import React from 'react'

import { VStack, Text, Skeleton } from '@chakra-ui/react'
import { DownloadSubtitle } from 'components/Tables/DownloadSubtitle'

export const GetSubtitles = ({ subtitles, title, videoId }) => {
  return (
    <VStack alignItems="flex-start" py="16px" spacing="16px">
      <Text as="h3" variant="h3">
        {title}
      </Text>
      {!subtitles && <Skeleton w="100%" h="130px" />}
      {subtitles && <DownloadSubtitle subtitles={subtitles} videoId={videoId} />}
    </VStack>
  )
}
