import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Input,
  VStack,
  FormControl,
  FormErrorMessage,
  Box,
  Heading,
  InputGroup,
  InputRightAddon,
  Icon,
  Text,
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import UploadIcon from '@mui/icons-material/Upload'

export const UserSubtitleUpload = ({
  selectedFile,
  setSelectedFile,
  setFileContent,
  setUnknownWordsData,
  handleFileUpload,
}) => {
  const { t } = useTranslation()
  const acceptedFiles = '.srt' //, .vtt, .ass, .sub
  const fileFormatMessage = t(`File is required. Supported file formats`) + ': ' + acceptedFiles

  useEffect(() => {
    setUnknownWordsData([])
    setSelectedFile(null)
  }, [])

  const initialValues = {
    file: undefined,
  }

  const validationSchema = Yup.object().shape({
    file: Yup.mixed()
      .required(fileFormatMessage)
      .test('fileFormat', fileFormatMessage, (value) => {
        if (!value) return false
        // Extract the file extension and check if it is 'srt'
        const fileExtension = value.name.split('.').pop()
        return fileExtension === 'srt'
      }),
  })

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  // Write short file info if use Drag and drop
  const handleDrop = (event, setFieldValue) => {
    event.preventDefault()
    const files = event.dataTransfer.files
    if (files && files.length > 0) {
      const file = files[0]
      setFieldValue('file', file)
      setSelectedFile(file)
      readFileContent(file)
    }
    setUnknownWordsData([])
  }

  // Read file content
  const readFileContent = (file) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const content = e.target.result
        setFileContent(content)
      }
      reader.readAsText(file)
    }
    setUnknownWordsData([])
  }

  return (
    <Box layerStyle="outline" color="white">
      <VStack spacing="36px" py="20px">
        <Heading as="h2" size="lg" textAlign="center">
          {t(`Upload Your subtitles`)}
        </Heading>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            handleFileUpload(values.file)
            actions.setSubmitting(false)
          }}
        >
          {({ setFieldValue, errors, touched }) => (
            <Form
              style={{
                alignSelf: 'stretch',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <InputGroup size="lg" maxW="600px" variant="file" colorScheme="teal">
                <FormControl isInvalid={errors.file && touched.file}>
                  <Input
                    id="file"
                    type="file"
                    accept={acceptedFiles}
                    onChange={(event) => {
                      const file = event.currentTarget.files[0]
                      setFieldValue('file', file)
                      setSelectedFile(file)
                      readFileContent(file)
                    }}
                    style={{ display: 'none' }}
                  />
                  <Button
                    as="label"
                    size="lg"
                    htmlFor="file"
                    variant="input"
                    width="full"
                    cursor="pointer"
                    onDragOver={handleDragOver}
                    onDrop={(event) => handleDrop(event, setFieldValue)}
                    border="3px solid"
                    height={{ base: '100px', md: '48px' }}
                  >
                    {selectedFile ? (
                      <Text whiteSpace="break-spaces" color="textGrey">
                        {selectedFile.name}
                      </Text>
                    ) : (
                      <Text whiteSpace="break-spaces" color="textGrey">
                        {t(`Drag & drop a file here or click to find it on your device`)}
                      </Text>
                    )}
                  </Button>
                  {errors.file && <FormErrorMessage>{errors.file}</FormErrorMessage>}
                </FormControl>
                <InputRightAddon
                  border="3px solid"
                  borderColor="tealButton"
                  borderLeft="0"
                  height={{ base: '100px', md: '48px' }}
                >
                  <Button variant="noDecoration" type="submit">
                    <Icon as={UploadIcon} />
                  </Button>
                </InputRightAddon>
              </InputGroup>
            </Form>
          )}
        </Formik>
      </VStack>
    </Box>
  )
}
