export const MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24

export const PRESENT_TO_RECOMMEND = 71 //TODO =>80

export const TRANSLATED_SUB_COLOR = '#33cc33'

export const COUNT_LEARNING_WORDS_PER_SESSION = 10

export const COUNT_CARDS_ON_PAGE_FOR_PAGINATION = 12

// export const TMDB_BASE_URL = 'https://www.themoviedb.org'

// export const TMDB_BASE_IMG_URL = 'https://image.tmdb.org/t/p/'

// export const TMDB_URL_IMG_PROFILE_SMALL = TMDB_BASE_IMG_URL + 'w45' // NOT used
// export const TMDB_URL_IMG_PROFILE_MEDIUM = TMDB_BASE_IMG_URL + 'w185'
// export const TMDB_URL_IMG_PROFILE_BIG = TMDB_BASE_IMG_URL + 'h632' // NOT used
// export const TMDB_URL_IMG_PROFILE_ORIGINAL = TMDB_BASE_IMG_URL + 'original' // NOT used

// export const TMDB_URL_IMG_POSTER_SMALL = TMDB_BASE_IMG_URL + 'w342'
// export const TMDB_URL_IMG_POSTER_MIDDLE = TMDB_BASE_IMG_URL + 'w500'
// export const TMDB_URL_IMG_POSTER_BIG = TMDB_BASE_IMG_URL + 'w780' // NOT used
// export const TMDB_URL_IMG_POSTER_ORIGINAL = TMDB_BASE_IMG_URL + 'original' // NOT used

// export const TMDB_URL_IMG_BACKDROP_SMALL = TMDB_BASE_IMG_URL + 'w300' // NOT used
// export const TMDB_URL_IMG_BACKDROP_MIDDLE = TMDB_BASE_IMG_URL + 'w780' // NOT used
// export const TMDB_URL_IMG_BACKDROP_BIG = TMDB_BASE_IMG_URL + 'w1280'
// export const TMDB_URL_IMG_BACKDROP_ORIGINAL = TMDB_BASE_IMG_URL + 'original' // NOT used

// https://developer.themoviedb.org/reference/configuration-details

// export const images = {
//   images: {
//     base_url: 'http://image.tmdb.org/t/p/',
//     secure_base_url: 'https://image.tmdb.org/t/p/',
//     backdrop_sizes: ['w300', 'w780', 'w1280', 'original'],
//     logo_sizes: ['w45', 'w92', 'w154', 'w185', 'w300', 'w500', 'original'],
//     poster_sizes: ['w92', 'w154', 'w185', 'w342', 'w500', 'w780', 'original'],
//     profile_sizes: ['w45', 'w185', 'h632', 'original'],
//     still_sizes: ['w92', 'w185', 'w300', 'original'],
//   },
//   change_keys: [
//     'adult',
//     'air_date',
//     'also_known_as',
//     'alternative_titles',
//     'biography',
//     'birthday',
//     'budget',
//     'cast',
//     'certifications',
//     'character_names',
//     'created_by',
//     'crew',
//     'deathday',
//     'episode',
//     'episode_number',
//     'episode_run_time',
//     'freebase_id',
//     'freebase_mid',
//     'general',
//     'genres',
//     'guest_stars',
//     'homepage',
//     'images',
//     'imdb_id',
//     'languages',
//     'name',
//     'network',
//     'origin_country',
//     'original_name',
//     'original_title',
//     'overview',
//     'parts',
//     'place_of_birth',
//     'plot_keywords',
//     'production_code',
//     'production_companies',
//     'production_countries',
//     'releases',
//     'revenue',
//     'runtime',
//     'season',
//     'season_number',
//     'season_regular',
//     'spoken_languages',
//     'status',
//     'tagline',
//     'title',
//     'translations',
//     'tvdb_id',
//     'tvrage_id',
//     'type',
//     'video',
//     'videos',
//   ],
// }
