import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { omitBy, isEmpty } from 'lodash'

import { Heading, Stack, useToast } from '@chakra-ui/react'

import { setDocumentTitle } from 'components/PageElements/helpers'
import { ServiceSubtitleUpload, UserSubtitleUpload, UnknownWords } from 'components/Modules/'

import { useUserContextState } from 'shared/contexts/user-context-provider'
import { useAnalyzeWordsMutation } from 'shared/mutations/userWord'

export const SubtitlePage = () => {
  const { t } = useTranslation()
  setDocumentTitle(t('Learn subtitle'))
  const location = useLocation()
  const { user } = useUserContextState()

  const toast = useToast()
  const toastIdRef = useRef() // Ref to keep track of the loading toast ID

  const { mutate: analyzeWords } = useAnalyzeWordsMutation()

  const subtitleURL = location.state?.url
  const videoId = location.state?.id

  const [selectedFile, setSelectedFile] = useState(null)
  const [fileContent, setFileContent] = useState('')
  const [unknownWordsData, setUnknownWordsData] = useState([])

  const handleFileUpload = (file) => {
    setUnknownWordsData([])
    // Show loading toast
    toastIdRef.current = toast({
      title: t(`Analyzing file...`),
      description: t(`Please wait.`),
      position: 'top-right',
      status: 'info',
      duration: null,
      isClosable: true,
    })

    const values = {
      from: user.currentLearningLanguage,
      to: user.knownLanguage,
      text: fileContent,
      title: file.title ? file.title : file.name,
      filmId: file.id ? file.id : null,
    }

    const filteredValues = omitBy(values, (value) => !value)

    analyzeWords(filteredValues, {
      onSuccess: (response) => {
        // Close the loading toast
        if (toastIdRef.current) {
          toast.close(toastIdRef.current)
        }
        toast({
          title: t(`File analyzed`),
          position: 'top-right',
          status: 'success',
          description: file?.name,
          duration: 5000,
          isClosable: true,
        })
        setUnknownWordsData(response)
      },
      onError: (error) => {
        // Close the loading toast
        if (toastIdRef.current) {
          toast.close(toastIdRef.current)
        }
        toast({
          title: t(`File analysis error`),
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        })
      },
    })
  }

  return (
    <Stack
      paddingX="16px"
      justify="flex-start"
      align="center"
      spacing="28px"
      overflow="hidden"
      alignSelf="stretch"
    >
      {!subtitleURL ? (
        <Heading as="h1" size="xl" color="white" textAlign="center">
          {t(`Translate Your subtitles`)}
        </Heading>
      ) : (
        <Heading as="h1" size="xl" color="white" textAlign="center">
          {t(`Translate subtitles for:`)} {selectedFile?.title}
        </Heading>
      )}

      {!subtitleURL && (
        <UserSubtitleUpload
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          setFileContent={setFileContent}
          setUnknownWordsData={setUnknownWordsData}
          handleFileUpload={handleFileUpload}
        />
      )}
      {subtitleURL && (
        <ServiceSubtitleUpload
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          fileContent={fileContent}
          setFileContent={setFileContent}
          user={user}
          handleFileUpload={handleFileUpload}
          subtitleURL={subtitleURL}
          videoId={videoId}
        />
      )}

      {!isEmpty(unknownWordsData) && (
        <UnknownWords
          unknownWordsData={unknownWordsData}
          subtitleText={fileContent}
          selectedFile={selectedFile}
        />
      )}
    </Stack>
  )
}
