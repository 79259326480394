import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { FormField } from './FormField'
import { useResendVerificationEmailMutation } from 'shared/mutations/user'

export const EmailVerificationExpired = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const { mutate: resendVerificationLink } = useResendVerificationEmailMutation()

  const RestoreVerificationLinkSchema = Yup.object().shape({
    email: Yup.string().email(t(`Invalid email`)).required(t(`Please Enter your email`)),
  })

  const handleResendVerificationLink = (values) => {
    const { email } = values
    if (email) {
      resendVerificationLink(values, {
        onSuccess: () => {
          toast({
            position: 'top-right',
            status: 'success',
            title: t(`Email with verification link send`),
            isClosable: true,
          })
          onClose()
        },
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="350px">
        <ModalHeader textAlign="center">{t(`You email verification link expired!`)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb="16px" textAlign="center">
            {t(`Looks like your verification token is invalid or expired.`)}
          </Text>
          <Text mb="16px" textAlign="center">
            {t(`Please check you email and try again or resend verification token.`)}
          </Text>
          <Formik
            enableReinitialize
            validationSchema={RestoreVerificationLinkSchema}
            initialValues={{
              email: '',
            }}
            onSubmit={handleResendVerificationLink}
          >
            {() => (
              <Form>
                <FormField name="email" placeholder={t(`Enter Email`)} />
                <ModalFooter>
                  <Button size="lg" type="submit">
                    {t(`Resend verification link`)}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
