export const List = {
  baseStyle: {
    item: {
      color: 'white',
      ml: '16px',
      fontSize: '1.125rem',
      fontWeight: '450',
      lineHeight: '26.5px',
      _before: {
        fontSize: '1.25rem',
        verticalAlign: 'center',
        display: 'inline-block',
        width: '0.75em',
      },
    },
  },
  variants: {
    unordered: {
      item: {
        color: 'textGreyLight',
        ml: '16px',
        fontSize: '1.125rem',
        fontWeight: '450',
        lineHeight: '26.5px',
        _before: {
          fontSize: '1.25rem',
          verticalAlign: 'center',
          display: 'inline-block',
          width: '0.75em',
        },
      },
    },
  },
}
