import React from 'react'
import { Outlet } from 'react-router-dom'
import { Box, Container, Flex } from '@chakra-ui/react'
import { isEmpty } from 'lodash'

import { Header } from '../PageElements/Header'
import { Footer } from 'components/PageElements/Footer'

import { useUserContextState } from 'shared/contexts/user-context-provider'

export const Layout = () => {
  const { user } = useUserContextState()

  const isNotLogIn = isEmpty(user)
  const langIsSet = user.isLanguagesSet

  return (
    <Flex direction="column" minH="100vh" bgColor={isNotLogIn && 'mainPageBlack'}>
      <Header />

      {!isNotLogIn && langIsSet && (
        <Container as="main" maxW="container.xl" flex="1" p="28px 16px 40px 16px">
          <Outlet />
        </Container>
      )}
      {(isNotLogIn || !langIsSet) && <Box fit="contain" h={`calc(100vh - 295px)`} />}

      <Footer />
    </Flex>
  )
}
