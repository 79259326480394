export const Tabs = {
  variants: {
    unstyled: {
      tab: {
        fontSize: 'xl',
        fontWeight: 'extrabold',
        borderBottom: '2px solid',
        borderColor: 'transparent',
        color: 'textGrey',
        transitionDuration: '500ms', // Move these transition properties here
        // transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
        pl: '0',
        pr: '0',
        ml: '16px',
        mr: '16px',
        _selected: {
          color: 'white',
          borderBottomColor: 'teal',
        },
        _active: {
          background: 'transparent',
        },
        _focus: {
          boxShadow: 'none',
        },
        _hover: {
          color: 'white',
        },
      },
      // tabpanel: {
      //   p: '0',
      //   mt: '33px',
      // },
      // tabindicator: {
      //   borderColor: 'teal', // Customize the border color
      //   borderWidth: '2px', // Customize the border width
      //   borderRadius: '1px', // Customize the border radius
      //   height: '2px', // Customize the height
      // },
    },
  },
}
