import React from 'react'

import { VStack, Text, SkeletonText } from '@chakra-ui/react'

export const Overview = ({ description, title }) => {
  return (
    <VStack alignItems="flex-start" py="16px" spacing="16px">
      <Text as="h3" variant="h3">
        {title}
      </Text>
      {!description && <SkeletonText w="100%" noOfLines={5} spacing="2" skeletonHeight="4" />}
      <Text fontSize="md">{description}</Text>
    </VStack>
  )
}
