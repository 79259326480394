import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  TabIndicator,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react'

import DataUsageIcon from '@mui/icons-material/DataUsage'
import SubtitlesIcon from '@mui/icons-material/Subtitles'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import CheckIcon from '@mui/icons-material/Check'
import SettingsIcon from '@mui/icons-material/Settings'

import { setDocumentTitle } from 'components/PageElements/helpers'
import { Statistics } from '../components/Modules/statistics'
import { UserSubtitleList } from '../components/Tables/UserSubtitleList'
import { SearchSubtitle } from '../components/Search/SearchSubtitle'

import { Settings, TabItemsList } from 'components/PageElements/Profile'

import { useIndexMySubtitles } from 'shared/queries/subtitles'

export const MyProfilePage = () => {
  const { t } = useTranslation()
  setDocumentTitle(t('Profile'))

  const displayMenuItems = useBreakpointValue({ base: true, lg: false })

  const { data: indexMySubtitles, isLoading: isIndexMySubtitlesLoading } = useIndexMySubtitles()

  return (
    <>
      <Tabs variant="unstyled">
        <TabList justifyContent="center">
          {!displayMenuItems ? (
            <>
              <Tab>{t(`Settings`)}</Tab>
              <Tab>{t(`Statistic`)}</Tab>
              <Tab>{t(`Subtitles`)}</Tab>
              <Tab>{t(`Bookmarks`)}</Tab>
              <Tab>{t(`Favorite`)}</Tab>
              <Tab>{t(`Viewed`)}</Tab>
            </>
          ) : (
            <>
              <Tab>
                <SettingsIcon fontSize="large" />
              </Tab>
              <Tab>
                <DataUsageIcon fontSize="large" />
              </Tab>
              <Tab>
                <SubtitlesIcon fontSize="large" />
              </Tab>
              <Tab>
                <BookmarkBorderIcon fontSize="large" />
              </Tab>
              <Tab>
                <FavoriteBorderIcon fontSize="large" />
              </Tab>
              <Tab>
                <CheckIcon fontSize="large" />
              </Tab>
            </>
          )}
        </TabList>
        <TabIndicator mt="-1.5px" pr="0" pl="0" height="2px" bg="teal" borderRadius="1px" />

        <TabPanels color="white" mt="28px">
          <TabPanel p={0}>
            <Settings />
          </TabPanel>
          <TabPanel p={0}>
            <Statistics />
          </TabPanel>
          <TabPanel p={0}>
            <VStack spacing="28px">
              <SearchSubtitle />
              <UserSubtitleList
                indexMySubtitles={indexMySubtitles}
                isIndexMySubtitlesLoading={isIndexMySubtitlesLoading}
              />
            </VStack>
          </TabPanel>
          <TabPanel p={0}>
            <TabItemsList variant={'isBookmark'} notFoundLabel={t(`Bookmarks`)} />
          </TabPanel>
          <TabPanel p={0}>
            <TabItemsList variant={'isFavorite'} notFoundLabel={t(`Favorite`)} />
          </TabPanel>
          <TabPanel p={0}>
            <TabItemsList variant={'isViewed'} notFoundLabel={t(`Viewed`)} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}
