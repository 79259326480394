export const languagesList = {
  eng: {
    code: 'en-US',
    name: 'English',
    english_name: 'English',
  },
  deu: {
    code: 'de-DE',
    name: 'Deutsch',
    english_name: 'German',
  },
  spa: {
    code: 'es-ES',
    name: 'Español',
    english_name: 'Spanish',
  },
  ukr: {
    code: 'uk-UA',
    name: 'Українська',
    english_name: 'Ukrainian',
  },
  rus: {
    code: 'ru-RU',
    name: 'Русский',
    english_name: 'Russian',
  },
}

export const languageLevel = {
  A1: {
    code: 'A1',
    name: 'CEFR A1 Level (Basic)',
    english_name: 'CEFR A1 Level (Basic)',
  },
  A2: {
    code: 'A2',
    name: 'CEFR A2 Level (Basic)',
    english_name: 'CEFR A2 Level (Basic)',
  },
  B1: {
    code: 'B1',
    name: 'CEFR B1 Level (Independent)',
    english_name: 'CEFR B1 Level (Independent)',
  },
  B2: {
    code: 'B2',
    name: 'CEFR B2 Level (Independent)',
    english_name: 'CEFR B2 Level (Independent)',
  },
  C1: {
    code: 'C1',
    name: 'CEFR C1 Level (Proficient)',
    english_name: 'CEFR C1 Level (Proficient)',
  },
  C2: {
    code: 'C2',
    name: 'CEFR C2 Level (Proficient)',
    english_name: 'CEFR C2 Level (Proficient)',
  },
}
