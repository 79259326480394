import React from 'react'

import { useTranslation } from 'react-i18next'

import { Box, Badge, VStack, Text, HStack, Link, Spacer, Skeleton } from '@chakra-ui/react'

import { yearRelease, convertDateRelease } from 'helpers'

import { Duration } from 'components/PageElements/MovieSeries'

export const MainInfo = ({ infoData, showKnownLabel = true }) => {
  const { t } = useTranslation()

  if (!infoData) {
    return (
      <Box pt="20px" pb="16px">
        <Skeleton w="100%" h="115px" />
      </Box>
    )
  }

  const convertedReleaseYear = yearRelease(infoData?.releaseDate)
  const convertedReleaseDate = convertDateRelease(infoData?.releaseDate)

  return (
    <HStack alignItems="flex-start">
      <VStack alignItems="flex-start" pt="20px" pb="16px" spacing="12px">
        <Text as="h1" variant="h1">
          {infoData?.title} {infoData?.releaseDate && '(' + convertedReleaseYear + ')'}
        </Text>
        {/*  TO DO. Correct === to !=== when finish, or for future */}
        {/* {title === original_title && (
              <Text as="h2" variant="h2" fontSize="md" color="textGreyLight">
                {original_title}
              </Text>
          )} */}
        <HStack spacing="12px" flexWrap="wrap">
          {infoData?.classification && (
            <Badge colorScheme="teal" variant="outline" fontSize="md">
              {infoData?.classification}
            </Badge>
          )}
          {infoData?.releaseDate && <Text fontSize="md">{convertedReleaseDate}</Text>}
          {infoData?.genres && (
            <>
              <Text>|</Text>
              {infoData?.genres?.map((genre, index, array) => (
                <Link key={index} color="white" fontSize="md" _hover={{ color: 'teal.500' }}>
                  {genre}
                  {index < array.length - 1 && ', '}
                </Link>
              ))}
            </>
          )}
          {infoData?.duration && (
            <>
              <Text>|</Text>
              <Duration duration={infoData?.duration} />
            </>
          )}
        </HStack>
        {infoData?.tagline && (
          <Text fontSize="md" color="textGreyLight">
            {infoData?.tagline}
          </Text>
        )}
      </VStack>
      {infoData.knownWordsCount && infoData.wordsCount && showKnownLabel && (
        <>
          <Spacer />
          <Box pt="20px">
            <Text variant="h4" whiteSpace="nowrap">
              {t(`Known words`)}
            </Text>
            <Text variant="h4" align="center" color="teal.500" mt="4px">
              {infoData?.knownWordsCount} / {infoData?.wordsCount}
            </Text>
          </Box>
        </>
      )}
    </HStack>
  )
}
