import React from 'react'
import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { omitBy } from 'lodash'

import { Box, Button, VStack, useToast, Heading, Flex } from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { QueriesKeysEnum } from 'shared/queries/queries-keys-enum'
import axios from 'shared/api/setup'

import { FileUpload } from '.'
import { FormField } from 'components/Modals/FormField'
import { useUserContextStateDispatch } from 'shared/contexts/user-context-provider'

import { useUpdateMyUserProfileMutation } from 'shared/mutations/userProfile'

export const PersonalInformation = ({ user, refetchUserProfile }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const queryCache = useQueryClient()

  const { mutate: updateMyUserProfile } = useUpdateMyUserProfileMutation()
  const { updateUserContext } = useUserContextStateDispatch()

  const validationSchema = Yup.object({
    firstName: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string().email(t(`Invalid email address`)).required(t(`Email is required`)),
  })

  const initialValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    avatarUrl: null,
  }

  const handleEditProfile = async (values) => {
    const filteredValues = omitBy(values, (value, key) => !value || key === 'avatarUrl')
    if (values?.avatarUrl) {
      const formData = new FormData()
      formData.append('file', values?.avatarUrl)
      const { url } = await axios.post('/media/avatars', formData)
      filteredValues.avatarUrl = url
    }
    updateMyUserProfile(filteredValues, {
      onSuccess: () => {
        updateUserContext({
          type: 'update',
          payload: { user: { ...user, ...filteredValues } },
        })
        queryCache.invalidateQueries([QueriesKeysEnum.user])
        refetchUserProfile()
        toast({
          position: 'top-right',
          status: 'success',
          title: t(`Personal information updated`),
          isClosable: true,
        })
      },
    })
  }

  return (
    <Box layerStyle="outline" p="24px">
      <Heading size="lg" textAlign="center">
        {t(`Personal information`)}
      </Heading>
      <Formik
        initialValues={initialValues}
        validateOnChange={false}
        validateOnMount={false}
        validationSchema={validationSchema}
        onSubmit={handleEditProfile}
      >
        {({ setFieldValue }) => (
          <Form>
            <VStack>
              <Flex wrap="wrap" justifyContent="center" alignItems="center" my="8px">
                <VStack spacing="16px" m="8px 16px 16px 16px" w="300px">
                  <FormField
                    name="firstName"
                    placeholder={t(`First Name`)}
                    minH="36px"
                    color="black"
                  />
                  <FormField
                    name="lastName"
                    placeholder={t(`Last Name`)}
                    minH="36px"
                    color="black"
                  />
                  <FormField
                    name="email"
                    placeholder={t(`Enter Email`)}
                    color="black"
                    minH="36px"
                    isDisabled={true}
                  />
                </VStack>

                <FileUpload
                  name="avatarUrl"
                  preview={user?.avatarUrl}
                  onChange={setFieldValue}
                />
              </Flex>
              <Button size="lg" mt={4} type="submit" align="center">
                {t(`Save`)}
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
