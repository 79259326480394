import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash.isempty'

import {
  Heading,
  Stack,
  Button,
  Input,
  VStack,
  FormControl,
  FormErrorMessage,
  Box,
  InputGroup,
  InputRightAddon,
  Icon,
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import UploadIcon from '@mui/icons-material/Upload'

import { setDocumentTitle } from 'components/PageElements/helpers'

export const AnalysisSubtitles = () => {
  const { t } = useTranslation()
  setDocumentTitle(t('Learn subtitle'))

  const [selectedFile, setSelectedFile] = useState(null)

  const [filteredSubtitleText, setFilteredSubtitleText] = useState('')

  const acceptedFiles = '.srt' //, .vtt, .ass, .sub
  const fileFormatMessage = t(`File is required. Supported file formats`) + ': ' + acceptedFiles

  useEffect(() => {
    // setUnknownWordsData([])
    setSelectedFile(null)
  }, [])

  const initialValues = {
    file: undefined,
  }

  const validationSchema = Yup.object().shape({
    file: Yup.mixed()
      .required(fileFormatMessage)
      .test('fileFormat', fileFormatMessage, (value) => {
        if (!value) return false
        // Extract the file extension and check if it is 'srt'
        const fileExtension = value.name.split('.').pop()
        return fileExtension === 'srt'
      }),
  })

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  // Write short file info if use Drag and drop
  const handleDrop = (event, setFieldValue) => {
    event.preventDefault()
    const files = event.dataTransfer.files
    if (files && files.length > 0) {
      const file = files[0]
      setFieldValue('file', file)
      setSelectedFile(file)
      readFileContent(file)
    }
  }

  // Read file content
  const readFileContent = (file) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const content = e.target.result
        filterAndPrintCapitalizedWords(content)
      }
      reader.readAsText(file)
    }
  }

  function filterAndPrintCapitalizedWords(subtitleText) {
    // const regex = /['",!.]*\b[A-Z]{2,}\b['",!.]*|['",!.]*\b[A-Z][a-z]*\b['",!.]*/g
    const regex = /[",!.]*\b[A-Z]{2,}\b[",!.]*|[",!.]*\b[A-Z][a-z]*\b[",!.]*/g

    let matches = subtitleText.match(regex)

    if (!matches) return

    // Trim symbols, filter unique matches, and sort alphabetically
    const uniqueAndSortedMatches = [
      ...new Set(matches.map((word) => word.replace(/^['",!.]*|['",!.]*$/g, ''))),
    ].sort()

    const filteredWords = uniqueAndSortedMatches.join(', ')

    setFilteredSubtitleText(filteredWords)
  }

  const handleDownloadSubtitles = () => {
    const originalFileName = selectedFile.name

    // Create a Blob with the translated text
    const blob = new Blob([filteredSubtitleText], { type: 'text/plain' })

    // Create a URL for the Blob
    const fileURL = URL.createObjectURL(blob)

    // Modify the original file name to include '_translated' before the extension
    const fileNameParts = originalFileName.split('.')
    const fileNameWithoutExtension = fileNameParts.slice(0, -1).join('.')
    // const fileExtension = fileNameParts.pop()
    const newFileName = `${fileNameWithoutExtension}_analyzed.txt`

    // Create a temporary anchor element to trigger the download
    const a = document.createElement('a')
    a.href = fileURL
    a.download = newFileName // Set the file name for the download
    document.body.appendChild(a) // Append the anchor to the body
    a.click() // Trigger the download

    // Clean up by removing the anchor element and revoking the Blob URL
    document.body.removeChild(a)
    URL.revokeObjectURL(fileURL)
  }

  return (
    <Stack
      paddingX="16px"
      justify="flex-start"
      align="center"
      spacing="28px"
      overflow="hidden"
      alignSelf="stretch"
    >
      <Heading as="h1" size="xl" color="white" textAlign="center">
        {t(`Analysis Subtitles Admin`)}
      </Heading>

      <Box layerStyle="outline" color="white">
        <VStack spacing="36px" py="20px">
          <Heading as="h2" size="lg" textAlign="center">
            {t(`Upload Your subtitles`)}
          </Heading>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false)
            }}
          >
            {({ setFieldValue, errors, touched }) => (
              <Form
                style={{
                  alignSelf: 'stretch',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <InputGroup size="lg" maxW="600px" variant="file" colorScheme="teal">
                  <FormControl isInvalid={errors.file && touched.file}>
                    <Input
                      id="file"
                      type="file"
                      accept={acceptedFiles}
                      onChange={(event) => {
                        const file = event.currentTarget.files[0]
                        setFieldValue('file', file)
                        setSelectedFile(file)
                        readFileContent(file)
                      }}
                      style={{ display: 'none' }}
                    />
                    <Button
                      as="label"
                      size="lg"
                      htmlFor="file"
                      variant="input"
                      width="full"
                      cursor="pointer"
                      onDragOver={handleDragOver}
                      onDrop={(event) => handleDrop(event, setFieldValue)}
                      border="3px solid"
                    >
                      {selectedFile
                        ? selectedFile.name
                        : t(`Drag & drop a file here or click to find it on your device`)}
                    </Button>
                    {errors.file && <FormErrorMessage>{errors.file}</FormErrorMessage>}
                  </FormControl>
                  <InputRightAddon border="3px solid" borderColor="tealButton" borderLeft="0">
                    <Button variant="noDecoration" type="submit">
                      <Icon as={UploadIcon} />
                    </Button>
                  </InputRightAddon>
                </InputGroup>
              </Form>
            )}
          </Formik>
        </VStack>
      </Box>

      {!isEmpty(filteredSubtitleText) && (
        <Button size="lg" onClick={handleDownloadSubtitles}>
          {t(`Download analyzed subtitles`)}
        </Button>
      )}
    </Stack>
  )
}
