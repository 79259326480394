import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Flex, Image, useDisclosure } from '@chakra-ui/react'

import { Header } from 'components/PageElements/Header'
import { Footer } from 'components/PageElements/Footer'
import { setDocumentTitle } from 'components/PageElements/helpers'
import { ResetPasswordModal } from 'components/Modals'

export const ResetPassword = () => {
  setDocumentTitle('Reset Password')
  const params = useParams()

  const {
    isOpen: isResetPasswordModalOpen,
    onOpen: onResetPasswordOpen,
    onClose: onResetPasswordModalClose,
  } = useDisclosure()

  useEffect(() => {
    if (params?.token) {
      onResetPasswordOpen()
    }
  }, [onResetPasswordOpen])

  return (
    <Flex direction="column" minH="100vh" bgColor="mainPageBlack">
      <Header />

      <Image mt="-100px" src="/img/background.png" alt="Background" fit="contain" h="100vh" />

      <Footer />

      <ResetPasswordModal
        isResetPasswordModalOpen={isResetPasswordModalOpen}
        onResetPasswordModalClose={onResetPasswordModalClose}
      />
    </Flex>
  )
}
