import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  Wrap,
  Menu,
  MenuButton,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  Button,
  Tooltip,
  InputGroup,
  Input,
  InputRightAddon,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
} from '@chakra-ui/react'
import { ChevronDownIcon, Search2Icon } from '@chakra-ui/icons'

import { genresMovie, certifications, languages, years } from './searchData'

export const Search = () => {
  const { t, i18n } = useTranslation()
  const currentLanguageCode = i18n.language.split('-')[0]
  const genresMovieCurrentLang = genresMovie[currentLanguageCode]
    ? genresMovie[currentLanguageCode]
    : genresMovie['en']

  const yearsList = years()

  return (
    <Box layerStyle="outline" spacing="16px">
      <Tabs variant="unstyled">
        <TabList justifyContent="center">
          <Tab>{t('Filters')}</Tab>
          <Tab>{t('Search')}</Tab>
        </TabList>

        <TabPanels>
          <TabPanel pl="0" pr="0">
            <Wrap direction="row" justify="center" spacing="16px" alignSelf="stretch">
              <Menu closeOnSelect={false} matchWidth={true}>
                <MenuButton
                  as={Button}
                  size="lg"
                  colorScheme="teal"
                  rightIcon={<ChevronDownIcon data-icon="CkChevronDown" />}
                >
                  {t('Filter')}
                </MenuButton>
                <MenuList>
                  <MenuOptionGroup defaultValue="Recommended" type="radio">
                    <MenuItemOption value="Recommended">Recommended</MenuItemOption>
                    <MenuItemOption value="Descending">Descending</MenuItemOption>
                  </MenuOptionGroup>
                </MenuList>
              </Menu>

              <Menu closeOnSelect={false} matchWidth={true} autoSelect={false}>
                <MenuButton
                  as={Button}
                  size="lg"
                  colorScheme="teal"
                  rightIcon={<ChevronDownIcon data-icon="CkChevronDown" />}
                >
                  {t('Genres')}
                </MenuButton>
                <MenuList>
                  <MenuOptionGroup type="checkbox">
                    {genresMovieCurrentLang.map((genre) => (
                      <MenuItemOption key={genre.id} value={genre.id}>
                        {t(genre.name)}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>

              <Menu closeOnSelect={false} matchWidth={true} autoSelect={false}>
                <MenuButton
                  as={Button}
                  size="lg"
                  colorScheme="teal"
                  rightIcon={<ChevronDownIcon data-icon="CkChevronDown" />}
                >
                  {t('Certification')}
                </MenuButton>
                <MenuList>
                  <MenuOptionGroup type="checkbox">
                    {certifications.map((item) => (
                      <MenuItemOption key={item.order} value={item.certification}>
                        <Tooltip
                          key={item.order}
                          hasArrow
                          label={item.meaning}
                          placement="right"
                          bg="mainGray"
                          border="2px solid"
                          borderColor="highliteLineGray"
                          borderRadius="10px"
                          openDelay={500}
                        >
                          {t(item.certification)}
                        </Tooltip>
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>

              <Menu closeOnSelect={false} matchWidth={true} autoSelect={false}>
                <MenuButton
                  as={Button}
                  size="lg"
                  colorScheme="teal"
                  rightIcon={<ChevronDownIcon data-icon="CkChevronDown" />}
                >
                  {t('Language')}
                </MenuButton>
                <MenuList>
                  <MenuOptionGroup type="checkbox">
                    {languages.map((language, index) => (
                      <MenuItemOption key={index} value={language.iso_639_1}>
                        {t(language.english_name)}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>

              <Menu closeOnSelect={false} matchWidth={true} autoSelect={false}>
                <MenuButton
                  as={Button}
                  size="lg"
                  colorScheme="teal"
                  rightIcon={<ChevronDownIcon data-icon="CkChevronDown" />}
                >
                  {t('Year')}
                </MenuButton>
                <MenuList>
                  <MenuOptionGroup type="checkbox">
                    {yearsList.map((year, index) => (
                      <MenuItemOption key={index} value={year}>
                        {year}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                </MenuList>
              </Menu>

              <Box align="center">
                <Text color="teal.500">{t('Rating')}</Text>
                <RangeSlider
                  aria-label={['min', 'max']}
                  defaultValue={[4, 10]}
                  min={0}
                  max={10}
                  // step={1}
                  width="150px"
                >
                  <RangeSliderTrack>
                    <RangeSliderFilledTrack />
                  </RangeSliderTrack>
                  <RangeSliderThumb index={0} />
                  <RangeSliderThumb index={1} />
                </RangeSlider>
              </Box>

              <Button size="lg" variant="outline" colorScheme="teal">
                {t('Reset')}
              </Button>
            </Wrap>
          </TabPanel>
          <TabPanel align="center">
            <InputGroup size="lg" h="53px" p="0 8px">
              <Input
                placeholder={t('Search') + '...'}
                variant="field"
                border="3px solid"
                borderColor="tealButton"
              />
              <InputRightAddon border="3px solid" borderColor="tealButton" borderLeft="0">
                <Button variant="link">
                  <Search2Icon boxSize={6} color="tealButton" />
                </Button>
              </InputRightAddon>
            </InputGroup>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}
