import React from 'react'

import { VStack, Text, Skeleton } from '@chakra-ui/react'

import { ImageSlider } from 'components/Modules/ImageSlider'

export const BackdropsSlider = ({ backdrops, title }) => {
  return (
    <VStack alignItems="flex-start" py="16px" spacing="16px">
      <Text as="h3" variant="h3">
        {title}
      </Text>
      {!backdrops && <Skeleton w="100%" h="400px" />}

      <ImageSlider images={backdrops} />
    </VStack>
  )
}
