import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Box, HStack, Flex } from '@chakra-ui/react'

import { setDocumentTitle } from 'components/PageElements/helpers'
import {
  ActorsList,
  BackdropsSlider,
  CrewList,
  GetSubtitles,
  MainInfo,
  Overview,
  Poster,
  RatingStars,
  UserFavorites,
} from 'components/PageElements/MovieSeries'

import { useReadFilm } from 'shared/queries/film'
import { useIndexMyUserMovies } from 'shared/queries/userMovie'

export const Movie = () => {
  const { t } = useTranslation()
  const { movieId } = useParams()
  const [myFilmList, setMyFilmList] = useState([])

  const { data: filmData } = useReadFilm({
    id: movieId,
    includeCrew: true,
    includeReccomendationStats: true,
  })
  const { data: indexMyUserMovies } = useIndexMyUserMovies({ filmId: movieId })

  setDocumentTitle(t(filmData?.title))

  useEffect(() => {
    setMyFilmList(indexMyUserMovies)
  }, [indexMyUserMovies])

  return (
    <Flex
      layerStyle="outline"
      flexWrap={{ base: 'wrap', lg: 'nowrap' }}
      alignItems="flex-start"
      justify="center"
      p={0}
      spacing={0}
    >
      {/* Left side */}
      <Box minWidth={{ base: '370px', sm: '450px' }} flexBasis="450px" px="24px" py="24px" m={0}>
        <Poster infoData={filmData} />

        <HStack spacing="4px" mt="-32px" mb="16px" ml="64px">
          <RatingStars rating={filmData?.rating} {...{ boxSize: '36px' }} />
        </HStack>

        <UserFavorites movieId={movieId} myFilmList={myFilmList} setMyFilmList={setMyFilmList} />

        <CrewList crewRecords={filmData?.crewRecords} />
      </Box>
      {/* Right side */}
      <Box
        px="24px"
        minWidth={{ base: '100%', lg: '400px' }}
        flexBasis={{ base: '100%', lg: 'auto' }}
      >
        <MainInfo infoData={filmData} />

        <Overview description={filmData?.description} title={t('Overview')} />

        <ActorsList actorRecords={filmData?.actorRecords} title={t('Top Billed Cast')} />

        {filmData?.backdrops && (
          <BackdropsSlider backdrops={filmData?.backdrops} title={t('Media')} />
        )}

        <GetSubtitles
          subtitles={filmData?.subtitles}
          videoId={movieId}
          title={t('Subtitles prepared for You, based on Your current knowledge')}
        />
      </Box>
    </Flex>
  )
}
