export const Link = {
  variants: {
    tab: {
      fontSize: 'xl',
      fontWeight: 'extrabold',
      borderBottom: '2px solid',
      borderColor: 'transparent',
      color: 'textGrey',
      transitionDuration: '500ms',
      py: '25px',
      transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',

      _active: {
        color: 'white',
        borderBottomColor: 'teal',
      },
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        color: 'white',
        textDecoration: 'none',
      },
    },
    text: {
      color: 'teal.500',
      _active: {
        color: 'teal',
      },
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        color: 'white',
        textDecoration: 'none',
      },
    },
    paragraph: {
      fontSize: '16px',
      fontWeight: '450',
      lineHeight: '26.5px',
    },
  },
}
