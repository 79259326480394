import React from 'react'
import { useTranslation } from 'react-i18next'
import { omit } from 'lodash'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Text,
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FormField } from './FormField'

import { useRegisterUserMutation } from 'shared/mutations/user'

export const RegistrationModal = ({ buttonSize }) => {
  const { t } = useTranslation()
  const { mutate: onRegistration } = useRegisterUserMutation()
  const {
    isOpen: isRegistrationModalOpen,
    onOpen: onRegistrationModalOpen,
    onClose: onRegistrationModalClose,
  } = useDisclosure()
  const {
    isOpen: isVerificationModalOpen,
    onOpen: onVerificationModalOpen,
    onClose: onVerificationModalClose,
  } = useDisclosure()

  const RegistrationSchema = Yup.object().shape({
    email: Yup.string().email(t(`Invalid email`)).required(t(`Please Enter your email`)),
    password: Yup.string()
      .min(8, t(`Must Contain 8 Characters`))
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!@#$%^&*()\-_=+[{}\]|;:'",<.>/?€£¥₹])([a-zA-Z0-9`~!@#$%^&*()\-_=+[{}\]\\|;:'",<.>/?€£¥₹]+)(?=.{8,})/,
        t(`One Uppercase, One Lowercase, One Number and One Special Case Character`)
      )
      .required(t(`Please Enter your password`)),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t(`Passwords does not match`))
      .required(t(`Please re-type your password`)),
    termsAndConditions: Yup.boolean()
      .required(t(`Required`))
      .oneOf([true], t(`You must accept the terms and conditions.`)),
  })

  const handleSignUp = async (values) => {
    values.acceptedTermsVersion = 1 // TODO
    const filteredValues = omit(values, ['confirmPassword', 'termsAndConditions'])

    onRegistration(filteredValues, {
      onSuccess: () => {
        onRegistrationModalClose()
        onVerificationModalOpen()
      },
    })
  }

  return (
    <>
      <Button size={buttonSize} onClick={onRegistrationModalOpen}>
        {t(`Sign up`)}
      </Button>

      <Modal isOpen={isRegistrationModalOpen} onClose={onRegistrationModalClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW="350px">
          <ModalHeader>{t(`Registration`)}</ModalHeader>

          <Formik
            initialValues={{
              email: '',
              password: '',
              confirmPassword: '',
              termsAndConditions: false,
            }}
            validationSchema={RegistrationSchema}
            onSubmit={(values) => {
              handleSignUp(values)
            }}
          >
            {({ values, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <FormField name="email" placeholder={t(`Enter Email`)} />
                  <FormField name="password" type="password" placeholder={t(`Enter Password`)} />
                  <FormField
                    name="confirmPassword"
                    type="password"
                    placeholder={t(`Confirm Password`)}
                  />
                  <FormField
                    name="termsAndConditions"
                    type="checkbox"
                    placeholder={t(`I agree with`)}
                    linkText={t(`terms and conditions`)}
                    url="/terms-and-condition"
                  />
                </ModalBody>
                <ModalFooter>
                  <Button size="lg" mr="16px" onClick={onRegistrationModalClose}>
                    {t(`Cancel`)}
                  </Button>
                  <Button size="lg" type="submit" isDisabled={!values.termsAndConditions}>
                    {t(`Register`)}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>

      <Modal isOpen={isVerificationModalOpen} onClose={onVerificationModalClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW="350px">
          <ModalHeader>{t(`Registration`)}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb="30px">
            <Text textAlign="center">{t(`Please verify your Email.`)}</Text>
            <Text textAlign="center">{t(`Check spam folder.`)}</Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
