import React from 'react'

import { VStack, Text, Flex, Skeleton } from '@chakra-ui/react'

import { CardCast } from 'components/Cards/CardCast'

export const ActorsList = ({ actorRecords, title }) => {
  return (
    <VStack alignItems="flex-start" py="16px" spacing="16px">
      <Text as="h3" variant="h3">
        {title}
      </Text>
      <Flex wrap="wrap" alignSelf="stretch" justifyContent="space-around">
        {!actorRecords &&
          Array.from({ length: 5 }, (_, index) => (
            <Skeleton key={index} h="270px" w="140px" borderRadius="10px" p="8px" my="8px" />
          ))}
        {actorRecords?.map((cast) => (
          <CardCast
            key={cast.id}
            imageUrl={cast.imageUrl}
            name={`${cast.firstName} ${cast.lastName}`}
            character={cast.characterName}
          />
        ))}
      </Flex>
    </VStack>
  )
}
