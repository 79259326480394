import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, includes } from 'lodash'

import { Box, Button, VStack, useToast, Heading, Wrap, WrapItem } from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { FormFieldSelect } from 'components/Modals/FormField'
import { MyLearningLanguages } from 'components/Tables/MyLearningLanguages'
import { useUserContextStateDispatch } from 'shared/contexts/user-context-provider'

import { useSetVocabularyMutation } from 'shared/mutations/userProfile'
import { useIndexVocabularies } from 'shared/queries/vocabulary'

export const LanguageSetup = ({ user, refetchUserProfile }) => {
  const { t } = useTranslation()
  const toast = useToast()

  const { data: vocabularies, isLoading: isVocabulariesLoading } = useIndexVocabularies()
  const { mutate: onSetVocabularyMutation } = useSetVocabularyMutation()
  const { updateUserContext } = useUserContextStateDispatch()

  const validationSchema = Yup.object().shape({
    languageToLearn: Yup.string().required(t(`Select the language`)),
    languageLevel: Yup.string().required(t(`Select your level`)),
  })

  const initialValues = {
    languageToLearn: user?.currentLearningLanguage || '',
    languageLevel: '',
  }

  const mapLanguageToLearn = (userLang) => {
    return [
      ...new Set(vocabularies?.filter((item) => item.to === userLang)?.map((item) => item.from)),
    ]
  }

  const mapLanguageLevel = (userLang, studLang) => {
    return [
      ...new Set(
        vocabularies
          ?.filter((item) => item.to === userLang && item.from === studLang)
          ?.map((item) => item.level)
      ),
    ]
  }

  const mapVocabularyId = (values) => {
    const id = vocabularies
      ?.filter(
        (item) =>
          item.to === user.knownLanguage &&
          item.from === values.languageToLearn &&
          item.level === values.languageLevel
      )
      ?.map((item) => item.id)[0]
    return id
  }

  const handleSetVocabulary = async (values) => {
    const data = { vocabularyId: mapVocabularyId(values) }

    onSetVocabularyMutation(data, {
      onSuccess: (user) => {
        updateUserContext({
          type: 'update',
          payload: {
            user,
          },
        }),
          toast({
            position: 'top-right',
            status: 'success',
            title: t(`Language added`),
            isClosable: true,
          })
      },
    })
  }

  if (isVocabulariesLoading) {
    return null
  }

  return (
    <Box layerStyle="outline" p="24px">
      <Heading size="lg" textAlign="center">
        {t(`Language setup`)}
      </Heading>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSetVocabulary(values)
        }}
      >
        {({ values }) => (
          <Form>
            <VStack>
              <Wrap spacing="16px" justify="center" alignItems="center" my="16px">
                <WrapItem alignItems="center">
                  <FormFieldSelect
                    name="languageToLearn"
                    placeholder={t(`Select language`)}
                    dataList={mapLanguageToLearn(user?.knownLanguage)}
                    isDisabled={isEmpty(mapLanguageToLearn(user?.knownLanguage))}
                    color="black"
                    maxW="170px"
                  />
                </WrapItem>
                <WrapItem alignItems="center">
                  <FormFieldSelect
                    name="languageLevel"
                    placeholder={t(`Language level`)}
                    dataList={mapLanguageLevel(user?.knownLanguage, values?.languageToLearn)}
                    isDisabled={
                      isEmpty(mapLanguageLevel(user?.knownLanguage, values?.languageToLearn)) ||
                      includes(user?.learningLanguages, values?.languageToLearn)
                    }
                    color="black"
                    maxW="170px"
                  />
                </WrapItem>
                <WrapItem alignItems="center">
                  <Button
                    size="lg"
                    colorScheme="teal"
                    type="submit"
                    isDisabled={
                      isEmpty(mapLanguageLevel(user?.knownLanguage, values?.languageToLearn)) ||
                      includes(user?.learningLanguages, values?.languageToLearn)
                    }
                  >
                    {t(`Save`)}
                  </Button>
                </WrapItem>
              </Wrap>
            </VStack>
          </Form>
        )}
      </Formik>
      <Heading size="md" textAlign="center">
        {t(`My learning languages`)}
      </Heading>
      <MyLearningLanguages user={user} refetchUserProfile={refetchUserProfile} />
    </Box>
  )
}
