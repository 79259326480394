export const Badge = {
  baseStyle: {
    py: '2px',
    px: '8px',
    // textTransform: 'uppercase',
    // fontSize: '12px',
    // lineHeight: '18px',
    fontWeight: 'bold',
    borderRadius: '2px',
  },
  variants: {
    custom: {
      textTransform: 'none',
      fontSize: '16px',
      borderRadius: '4px',
      py: '4px',
      px: '8px',
    },
  },
}
