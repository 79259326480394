import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash.isempty'

import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
  Text,
  Box,
} from '@chakra-ui/react'

import { convertDateToHumanFull, languagesList } from 'helpers'

export const UserSubtitleList = ({ indexMySubtitles, isIndexMySubtitlesLoading }) => {
  const { t } = useTranslation()

  if (isIndexMySubtitlesLoading) {
    return null
  }

  if (isEmpty(indexMySubtitles)) {
    return (
      <Box
        layerStyle="outline"
        paddingX={{ base: '24px', sm: '30px', md: '40px' }}
        paddingY={{ base: '24px', sm: '40px' }}
      >
        <Text variant="h2" textAlign="center">
          {t(`It looks like you haven't downloaded subtitles yet.`)}
        </Text>
      </Box>
    )
  }

  return (
    <TableContainer
      border="1px"
      borderRadius="10px"
      borderColor="highliteLineGray"
      bg="mainGray"
      p="12px"
      alignSelf="stretch"
    >
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th textAlign="center">{t(`Film / TV Show`)}</Th>
            <Th textAlign="center" maxW="100px" minW="80px">
              {t(`Original`)}
            </Th>
            <Th textAlign="center" whiteSpace="break-spaces" maxW="100px" minW="80px">
              {t(`Unknown words on download`)}
            </Th>
            <Th textAlign="center" whiteSpace="break-spaces" maxW="100px" minW="80px">
              {t(`Words in subtitles`)}
            </Th>
            <Th textAlign="center" maxW="170px" minW="150px">
              {t(`Date`)}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {indexMySubtitles?.map((row, index) => (
            <Tr key={index}>
              <Td>
                {row.filmId ? (
                  <Link
                    as={ReactRouterLink}
                    variant="text"
                    to={row.isSeries ? '/shows/' + row.filmId : '/movies/' + row.filmId}
                  >
                    {row.title}
                  </Link>
                ) : (
                  <Text isTruncated>{row.title}</Text>
                )}
              </Td>
              <Td textAlign="center" maxW="100px" minW="80px">
                {languagesList[row.from].english_name}
              </Td>
              <Td textAlign="center" maxW="100px" minW="80px">
                {row.unknownWordsCount}
              </Td>
              <Td textAlign="center" maxW="100px" minW="80px">
                {row.wordsCount}
              </Td>
              <Td textAlign="center" maxW="160px" minW="130px">
                {convertDateToHumanFull(row.updatedAt)}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
