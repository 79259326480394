import React, { useCallback, useEffect, useReducer } from 'react'
import { useQueryClient } from 'react-query'
import { useDeleteMySessionMutation } from 'shared/mutations/user'
import { useReadMyUserProfile } from 'shared/queries/userProfile'

const UserStateContext = React.createContext({
  user: {},
})

const UserDispatchContext = React.createContext({
  updateUserContext: () => void 0,
  onLogout: () => new Promise(() => void 0),
  onOpenLoginModal: () => void 0,
})

const reducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload }
    case 'logout':
      return action.payload
    default: {
      return state
    }
  }
}

export const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { user: {} })
  const queryCache = useQueryClient()
  const { data: user, refetch: refetchUserProfile } = useReadMyUserProfile()
  const { mutate: handleLogout } = useDeleteMySessionMutation({
    onSuccess: async () => {
      localStorage.removeItem('AUTH_TOKEN')
      await queryCache.invalidateQueries()
      handleDispatch({ type: 'logout', payload: { user: {} } })
    },
  })

  const handleDispatch = useCallback((action) => dispatch(action), [])

  useEffect(() => {
    if (user) {
      handleDispatch({
        type: 'update',
        payload: {
          user,
        },
      })
    }
  }, [user])

  useEffect(() => {
    const token = localStorage.getItem('AUTH_TOKEN')
    if (token) {
      refetchUserProfile()
    }
    if (!token) {
      handleDispatch({ type: 'logout', payload: { user: {} } })
    }
  }, [])

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider
        value={{
          updateUserContext: handleDispatch,
          onLogout: handleLogout,
        }}
      >
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}
export const useUserContextState = () => {
  const context = React.useContext(UserStateContext)
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserContextProvider')
  }
  return context
}
export const useUserContextStateDispatch = () => {
  const context = React.useContext(UserDispatchContext)
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserContextProvider')
  }
  return context
}
