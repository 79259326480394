import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import {
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  MenuDivider,
  Image,
  Link,
  Container,
  Box,
  useBreakpointValue,
  HStack,
} from '@chakra-ui/react'
import { isEmpty } from 'lodash'

import {
  StyledNavLink,
  // LanguageSwitcher
} from './helpers'

import {
  useUserContextState,
  useUserContextStateDispatch,
} from 'shared/contexts/user-context-provider'

import { LoginModal, RegistrationModal } from '../Modals'

export const Header = () => {
  const { t } = useTranslation()
  const { user } = useUserContextState()
  const { onLogout } = useUserContextStateDispatch()

  const displayMenuItems = useBreakpointValue({ base: true, lg: false })
  const buttonSize = useBreakpointValue({ base: 'md', md: 'lg' })

  const isNotLogIn = isEmpty(user) || !user?.isLanguagesSet

  if (isNotLogIn) {
    return (
      <Box as="header" maxW="100vw" zIndex="10">
        <Container maxW="container.xl">
          <Stack
            height={{ base: '70px', sm: '100px' }}
            alignSelf="stretch"
            direction="row"
            justify="space-between"
            align="end"
            pl={{ base: 2, md: 6 }}
            pr={{ base: 2, md: 6 }}
          >
            <Link as={ReactRouterLink} to="/">
              <Image
                src="/img/logo.png"
                alt="Miovie mingle"
                width={{ base: '140px', sm: '170px' }}
                paddingBottom="4px"
              />
            </Link>
            <Stack
              direction="row"
              justify="space-start"
              align="center"
              alignSelf="stretch"
              spacing={{ base: '12px', md: '26px' }}
            >
              <LoginModal buttonSize={buttonSize} />
              <RegistrationModal buttonSize={buttonSize} />
            </Stack>
          </Stack>
        </Container>
      </Box>
    )
  }
  return (
    <Box maxW="100vw" borderColor="highliteLineGray" borderBottomWidth="1px" bg="mainGray">
      <Container maxW="container.xl" as="header">
        <Stack
          height={{ base: '70px', sm: '80px' }}
          alignSelf="stretch"
          direction="row"
          justify="space-between"
          align="end"
          pl={{ base: 6, sm: 12 }}
          pr={{ base: 4, sm: 8 }}
        >
          <Link as={ReactRouterLink} to="/movies">
            <Image
              src="/img/logo.png"
              alt="Miovie mingle"
              width={{ base: '140px', sm: '170px' }}
              paddingBottom="4px"
            />
          </Link>

          <Stack
            direction="row"
            justify="space-start"
            align="center"
            alignSelf="stretch"
            spacing="16px"
          >
            {!displayMenuItems && (
              <HStack as="nav" spacing="32px">
                <StyledNavLink variant="tab" to="/movies">
                  {t('Movies')}
                </StyledNavLink>
                <StyledNavLink variant="tab" to="/shows">
                  {t('TV Shows')}
                </StyledNavLink>
                <StyledNavLink variant="tab" to="/study">
                  {t('LL QUIZ')}
                </StyledNavLink>
                <StyledNavLink variant="tab" to="/subtitle">
                  {t('Learn subtitle')}
                </StyledNavLink>
              </HStack>
            )}
            {/* For future LanguageSwitcher */}
            {/* <LanguageSwitcher />   */}
            <Menu placement="bottom-end" autoSelect={false}>
              <MenuButton variant="outline">
                <Avatar
                  size="md"
                  bg="teal.500"
                  name={
                    user?.firstName && user?.lastName
                      ? `${user.firstName} ${user.lastName}`
                      : undefined
                  }
                  src={user?.avatarUrl}
                />
              </MenuButton>
              <MenuList>
                {displayMenuItems && (
                  <>
                    <StyledNavLink to="/movies">{t('Movies')}</StyledNavLink>
                    <StyledNavLink to="/shows">{t('TV Shows')}</StyledNavLink>
                    <StyledNavLink to="/study">{t('LL QUIZ')}</StyledNavLink>
                    <StyledNavLink to="/subtitle">{t('Learn subtitle')}</StyledNavLink>
                    {/* <MenuDivider /> */}
                  </>
                )}

                <StyledNavLink to="/profile">{t('Profile')}</StyledNavLink>
                {/* <StyledNavLink to="/#">{t('My vocabualry')}</StyledNavLink> */}
                <StyledNavLink to="/we-do">{t('What we do')}</StyledNavLink>
                <MenuDivider />
                <MenuItem {...(displayMenuItems && { fontSize: 'xl' })} onClick={onLogout}>
                  {t('Log out')}
                </MenuItem>
              </MenuList>
            </Menu>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}
