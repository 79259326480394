import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@chakra-ui/react'

// Convert duration from min to h & m
export const Duration = ({ duration }) => {
  const { t } = useTranslation()

  if (duration <= 60) {
    return <Text>{duration + t('m')}</Text>
  } else {
    // Calculate hours and minutes for durations greater than 60 minutes
    const hours = Math.floor(duration / 60)
    const minutes = duration % 60
    return <Text>{hours + t('h') + ' ' + minutes + t('m')}</Text>
  }
}
