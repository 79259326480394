import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Text,
  VStack,
  SimpleGrid,
} from '@chakra-ui/react'
import { useMyStatistics } from 'shared/queries/statistics'
import { Loading } from '.'

export const Statistics = () => {
  const { t } = useTranslation()

  const { data: myStatistics, isLoading: isMyStatisticsLoading } = useMyStatistics()

  if (isMyStatisticsLoading) {
    return <Loading />
  }

  return (
    <Box layerStyle="outline" color="white">
      <Text as="h2" fontSize="2xl" fontWeight="bold" align="center" mb={4}>
        {t('My statistics')}
      </Text>

      <SimpleGrid
        columns={[1, 2, 3, 4, 6]}
        spacing={10}
        justifyItems="center"
        align="center"
        mt={6}
        mb={4}
      >
        <VStack>
          <CircularProgress value={100} color="teal" size="150px" thickness="8px">
            <CircularProgressLabel>
              <Text fontSize="4xl" color="textGreyDark">
                {myStatistics?.todayNewWords}
              </Text>
            </CircularProgressLabel>
          </CircularProgress>
          <Text whiteSpace="break-spaces" fontSize="xl">
            {t(`Today's new words`)}
          </Text>
        </VStack>
        <VStack>
          <CircularProgress
            value={Math.round(myStatistics?.inStudyWords / myStatistics?.newWords)}
            color="teal"
            size="150px"
            thickness="8px"
          >
            <CircularProgressLabel>
              <Text fontSize="3xl" color="textGreyDark" mb="16px">
                {myStatistics?.inStudyWords}
              </Text>
              <Text fontSize="3xl" color="textGreyDark">
                {myStatistics?.newWords}
              </Text>
            </CircularProgressLabel>
          </CircularProgress>
          <Text whiteSpace="break-spaces" fontSize="xl">
            {t(`Words in studying`)}
          </Text>
        </VStack>
        <VStack>
          <CircularProgress value={100} color="teal" size="150px" thickness="8px">
            <CircularProgressLabel>
              <Text fontSize="4xl" color="textGreyDark">
                {myStatistics?.monthLearnedWords}
              </Text>
            </CircularProgressLabel>
          </CircularProgress>
          <Text whiteSpace="break-spaces" fontSize="xl">
            {t(`Words learned this month`)}
          </Text>
        </VStack>
        <VStack>
          <CircularProgress value={100} color="teal" size="150px" thickness="8px">
            <CircularProgressLabel>
              <Text fontSize="4xl" color="textGreyDark">
                {myStatistics?.knownWords}
              </Text>
            </CircularProgressLabel>
          </CircularProgress>
          <Text whiteSpace="break-spaces" fontSize="xl">
            {t(`My vocabulary`)}
          </Text>
        </VStack>
        <VStack>
          <CircularProgress value={100} color="teal" size="150px" thickness="8px">
            <CircularProgressLabel>
              <Text fontSize="4xl" color="textGreyDark">
                {myStatistics?.allWords}
              </Text>
            </CircularProgressLabel>
          </CircularProgress>
          <Text whiteSpace="break-spaces" fontSize="xl">
            {t(`All words`)}
          </Text>
        </VStack>
        <VStack>
          <CircularProgress value={100} color="teal" size="150px" thickness="8px">
            <CircularProgressLabel>
              <Text fontSize="4xl" color="textGreyDark">
                {myStatistics?.subtitlesCount}
              </Text>
            </CircularProgressLabel>
          </CircularProgress>
          <Text whiteSpace="break-spaces" fontSize="xl">
            {t(`Translated subtitles`)}
          </Text>
        </VStack>
      </SimpleGrid>
    </Box>
  )
}
