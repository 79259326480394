import React from 'react'
import { useTranslation } from 'react-i18next'

import { Flex, Stack, Text } from '@chakra-ui/react'

import { setDocumentTitle } from 'components/PageElements/helpers'

import { Search } from '../../components/Search/'

export const TVShowsPage = () => {
  const { t } = useTranslation()
  setDocumentTitle(t('TV Shows'))

  return (
    <Stack
      paddingX="16px"
      justify="flex-start"
      align="center"
      spacing="28px"
      overflow="hidden"
      alignSelf="stretch"
    >
      <Search />
      <Flex layerStyle="outline" h="300px" alignItems="center" justifyContent="center">
        <Text variant="h1" color="white">
          In development
        </Text>
      </Flex>
    </Stack>
  )
}
