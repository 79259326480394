import React from 'react'
import { useTranslation } from 'react-i18next'

import { setDocumentTitle } from 'components/PageElements/helpers'
import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Link,
  Spacer,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { Header } from 'components/PageElements/Header'
import { Footer } from 'components/PageElements/Footer'

export const WhatWeDoPage = () => {
  const { t } = useTranslation()
  setDocumentTitle(t('What we do'))

  const displayItems = useBreakpointValue({ base: true, lg: false })
  const imageSize = useBreakpointValue({ base: '300px', sm: '350px' })

  return (
    <Flex direction="column" minH="100vh" bgColor="mainBlack">
      <Header />
      <Container as="main" maxW="container.xl" flex="1" p="28px 16px 80px 16px">
        <Box layerStyle="outline" py="32px">
          <VStack spacing="32px">
            <Heading color="white" align="center">
              {t('What we do')}
            </Heading>

            <Text fontSize={{ base: 'xl', lg: '2xl' }} color="textGreyLight">
              It is a platform for language learning with the help of subtitles from movies or TV
              shows.
            </Text>

            <Flex direction={{ base: 'column', lg: 'row' }} alignItems="center" width="100%">
              <Image
                src="/img/what-we-do/Choose_film.jpeg"
                alt="Film"
                objectFit=" scale-down"
                minW="250px"
                maxW={imageSize}
              />
              <Spacer />
              <VStack
                spacing={{ base: '8px', lg: '32px' }}
                m="16px"
                justifyContent="center"
                maxW="500px"
                minW="300px"
              >
                {displayItems && (
                  <Icon as={KeyboardArrowUpIcon} fontSize="3xl" color="textGreyLight" />
                )}
                <Text fontSize="xl" color="textGreyLight">
                  {!displayItems && <Icon as={ArrowBackIosIcon} mb="-4px" mr="-4px" />}
                  Choose a movie or series from our platform.
                </Text>
                <Text fontSize="xl" color="textGreyLight">
                  Or upload your own subtitles.
                  {!displayItems && <Icon as={ArrowForwardIosIcon} mb="-4px" />}
                </Text>
                {displayItems && (
                  <Icon as={KeyboardArrowDownIcon} fontSize="3xl" color="textGreyLight" />
                )}
              </VStack>
              <Spacer />
              <Image
                src="/img/what-we-do/Chouse_sub.jpeg"
                alt="Film"
                objectFit=" scale-down"
                minW="250px"
                maxW={imageSize}
              />
            </Flex>
            {displayItems && <Divider borderColor="highliteLineGray" border="2px solid" />}
            <Flex direction={{ base: 'column', lg: 'row' }} alignItems="center" width="100%">
              <Image
                src="/img/what-we-do/Translate_film.jpg"
                alt="Film"
                objectFit=" scale-down"
                minW="250px"
                maxW={imageSize}
              />
              <Spacer />
              {displayItems && (
                <Icon as={KeyboardArrowUpIcon} fontSize="3xl" color="textGreyLight" />
              )}
              <HStack spacing="4px" m="16px" justifyContent="center" maxW="500px" minW="300px">
                {!displayItems && (
                  <Icon as={ArrowBackIosIcon} mb="-4px" mr="-4px" color="textGreyLight" />
                )}
                <VStack>
                  <Text fontSize="xl" color="textGreyLight">
                    We will analyze it and mark words that we think are new for you. Accuracy
                    will increase with each new movie.
                  </Text>
                  <Text fontSize="xl" color="textGreyLight">
                    You can also mark words that you already know and do not need to be
                    translated now or in the future.
                  </Text>
                  <Text fontSize="xl" color="textGreyLight">
                    After that, we will prepare subtitles with translation for you based on your
                    current knowledge.
                  </Text>
                  <Text fontSize="xl" color="textGreyLight">
                    New words will be stored to your vocabulary to learn them later in QUIZ.
                  </Text>
                </VStack>
                {!displayItems && (
                  <Icon as={ArrowForwardIosIcon} mb="-4px" color="textGreyLight" />
                )}
              </HStack>
              {displayItems && (
                <Icon as={KeyboardArrowDownIcon} fontSize="3xl" color="textGreyLight" />
              )}
              <Spacer />
              <Image
                src="/img/what-we-do/Translate_sub.jpg"
                alt="Film"
                objectFit=" scale-down"
                minW="250px"
                maxW={imageSize}
              />
            </Flex>
            {displayItems && <Divider borderColor="highliteLineGray" border="2px solid" />}
            <Flex direction={{ base: 'column', lg: 'row' }} alignItems="center" width="100%">
              <VStack spacing="16px" minW="250px" maxW={imageSize}>
                <Image
                  src="/img/what-we-do/Movie_1_use_1.jpeg"
                  alt="Movie_1_use_1"
                  objectFit=" scale-down"
                  minW="250px"
                  // maxW="350px"
                />
                <Image
                  src="/img/what-we-do/Movie_1_use_2.jpeg"
                  alt="Movie_1_use_2"
                  objectFit=" scale-down"
                  minW="250px"
                  // maxW="350px"
                />
              </VStack>
              <Spacer />
              {displayItems && (
                <Icon as={KeyboardArrowUpIcon} fontSize="3xl" color="textGreyLight" />
              )}
              <HStack spacing="4px" m="16px" justifyContent="center" maxW="500px" minW="300px">
                <Text fontSize="xl" color="textGreyLight">
                  {!displayItems && (
                    <Icon as={ArrowBackIosIcon} mb="-4px" mr="-4px" color="textGreyLight" />
                  )}
                  Connect subtitles to your movie and enjoy it.
                  {!displayItems && (
                    <Icon as={ArrowForwardIosIcon} mb="-4px" color="textGreyLight" />
                  )}
                </Text>
              </HStack>
              {displayItems && (
                <Icon as={KeyboardArrowDownIcon} fontSize="3xl" color="textGreyLight" />
              )}
              <Spacer />
              <VStack spacing="16px" minW="250px" maxW={imageSize}>
                <Image
                  src="/img/what-we-do/Movie_2_use_1.jpeg"
                  alt="Movie_2_use_1"
                  objectFit=" scale-down"
                  minW="250px"
                  // maxW={imageSize}
                />
                <Image
                  src="/img/what-we-do/Movie_2_use_2.jpeg"
                  alt="Movie_2_use_2"
                  objectFit=" scale-down"
                  minW="250px"
                  // maxW={imageSize}
                />
              </VStack>
            </Flex>
            {displayItems && <Divider borderColor="highliteLineGray" border="2px solid" />}
            <Flex direction={{ base: 'column', lg: 'row' }} alignItems="center" width="100%">
              <Image
                src="/img/what-we-do/QUIZ.png"
                alt="Film"
                objectFit=" scale-down"
                minW="250px"
                maxW={imageSize}
              />
              <Spacer />
              {displayItems && (
                <Icon as={KeyboardArrowUpIcon} fontSize="3xl" color="textGreyLight" />
              )}
              <VStack
                spacing={{ base: '8px', lg: '32px' }}
                m="16px"
                justifyContent="center"
                maxW="500px"
                minW="300px"
              >
                <Text fontSize="xl" color="textGreyLight">
                  {!displayItems && <Icon as={ArrowBackIosIcon} mb="-4px" mr="-4px" />}
                  Use QUIZ on our platform to learn new words.
                </Text>
                <Text fontSize="xl" color="textGreyLight">
                  Or use our Telegram bot.
                  {!displayItems && <Icon as={ArrowForwardIosIcon} mb="-4px" />}
                </Text>
              </VStack>
              {displayItems && (
                <Icon as={KeyboardArrowDownIcon} fontSize="3xl" color="textGreyLight" />
              )}
              <Spacer />
              <Box w="350px" display="flex" justifyContent="center" alignItems="center">
                <Image
                  src="/img/what-we-do/TG_Bot.jpeg"
                  alt="Film"
                  objectFit=" scale-down"
                  maxW="350px"
                  maxH="310px"
                />
              </Box>
            </Flex>

            <Link
              variant="text"
              fontSize={{ base: '2xl', lg: '3xl' }}
              isExternal
              href="https://movie-mingle.gitbook.io/movie-mingle/"
            >
              More details here <ExternalLinkIcon />
            </Link>
          </VStack>
        </Box>
      </Container>
      <Footer />
    </Flex>
  )
}
