export var QueriesKeysEnum
;((QueriesKeysEnum) => {
  QueriesKeysEnum['show'] = 'show'
  QueriesKeysEnum['userProfile'] = 'userProfile'
  QueriesKeysEnum['userMovie'] = 'userMovie'
  QueriesKeysEnum['crewPerson'] = 'crewPerson'
  QueriesKeysEnum['user'] = 'user'
  QueriesKeysEnum['userWord'] = 'userWord'
  QueriesKeysEnum['film'] = 'film'
  QueriesKeysEnum['vocabulary'] = 'vocabulary'
  QueriesKeysEnum['subtitle'] = 'subtitle'
  QueriesKeysEnum['user'] = 'user'
  QueriesKeysEnum['statistics'] = 'statistics'
})(QueriesKeysEnum || (QueriesKeysEnum = {}))
