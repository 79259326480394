export const Switch = {
  baseStyle: {
    track: {
      bg: 'dividerLineGrey',
      _checked: {
        bg: 'tealButton',
      },
      _focus: {
        boxShadow: 'none',
      },
    },
  },
}
