import React from 'react'

import StarIcon from '@mui/icons-material/Star'
import StarHalfIcon from '@mui/icons-material/StarHalf'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { Icon } from '@chakra-ui/icons'

// Calculate the number of each type of star and render rating
export const RatingStars = ({ rating, ...props }) => {
  const fullStars = Math.floor(Math.round(rating) / 2)
  const halfStars = Math.round(rating) % 2
  const emptyStars = 5 - fullStars - halfStars

  return (
    <>
      {Array.from({ length: fullStars }).map((_, index) => (
        <Icon as={StarIcon} key={index} color="teal.500" {...props} />
      ))}
      {halfStars === 1 && <Icon as={StarHalfIcon} color="teal.500" {...props} />}
      {Array.from({ length: emptyStars }).map((_, index) => (
        <Icon as={StarBorderIcon} key={index} color="teal.500" {...props} />
      ))}
    </>
  )
}
