export const Slider = {
  baseStyle: {
    track: {
      bg: 'white',
    },
    filledTrack: {
      bg: 'tealButton',
    },
    thumb: {
      border: '2px solid',
      borderColor: 'gray.18',
      borderRadius: '100%',
      _focus: {
        boxShadow: 'none',
      },
    },
  },
  sizes: {
    md: {
      thumb: { w: '20px', h: '20px' },
    },
  },
}
