import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { TableContainer, Table, Thead, Tbody, Tr, Th, Td, Button, Icon } from '@chakra-ui/react'

import { APP_PATHS } from 'paths'

import DownloadIcon from '@mui/icons-material/Download'
import { languagesList } from 'helpers'
import { useUserContextState } from 'shared/contexts/user-context-provider'

export const DownloadSubtitle = ({ subtitles, videoId }) => {
  const { t } = useTranslation()
  const { user } = useUserContextState()
  const navigate = useNavigate()

  const subtitlesForLearningLanguage = subtitles?.filter(
    (sub) => sub.language === user?.currentLearningLanguage
  )

  const handleDownloadSubtitles = (subtitleURL) => {
    const path = subtitleURL.split('/').slice(3).join('/')

    navigate(APP_PATHS.subtitle, { state: { url: path, id: videoId } })
  }

  return (
    <TableContainer
      border="1px"
      borderRadius="10px"
      borderColor="highliteLineGray"
      bg="mainGray"
      p="12px"
      alignSelf="stretch"
    >
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th textAlign="center">{t('Language')}</Th>
            <Th textAlign="center">{t('Download')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {subtitlesForLearningLanguage?.map((subtitle, index) => (
            <Tr key={index} borderBottom="1px" borderColor="highlightLineGray">
              <Td textAlign="center">{languagesList[subtitle.language].english_name}</Td>
              <Td textAlign="center">
                <Button
                  boxSize="40px"
                  p="0"
                  borderRadius="999px"
                  onClick={() => handleDownloadSubtitles(subtitle.url)}
                >
                  <Icon as={DownloadIcon} />
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
