import React from 'react'
import { useTranslation } from 'react-i18next'

import { TableContainer, Table, Thead, Tbody, Tr, Th, Td, Switch } from '@chakra-ui/react'

export const UnknownWordsList = ({ words, onSwitchChange }) => {
  const { t } = useTranslation()
  return (
    <TableContainer
      border="1px"
      borderRadius="10px"
      borderColor="highliteLineGray"
      bg="mainGray"
      p="12px"
      alignSelf="stretch"
    >
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th textAlign="center">{t('Word')}</Th>
            <Th textAlign="center">{t('Translation')}</Th>
            <Th textAlign="center" minW="80px" maxW="120px" whiteSpace="break-spaces">
              {t(`I know this word`)}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {words.map((wordItem) => (
            <Tr key={wordItem.id} borderBottom="1px" borderColor="highlightLineGray">
              <Td textAlign="center" whiteSpace="break-spaces">
                {wordItem.original}
              </Td>
              <Td textAlign="center" whiteSpace="break-spaces">
                {wordItem.translates[0]}
              </Td>
              <Td textAlign="center">
                <Switch
                  colorScheme="teal"
                  isChecked={wordItem?.known}
                  onChange={() => onSwitchChange(wordItem.id)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
