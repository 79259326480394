import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import isEmpty from 'lodash.isempty'
import { Box, Text } from '@chakra-ui/react'
import { FilmCard } from 'components/PageElements/filmCard'

import { useIndexFilms } from 'shared/queries/film'

import { APP_PATHS } from 'paths'

export const FilmsList = ({ itemsPerRow = 3 }) => {
  const navigate = useNavigate()
  const handleNavigateToFilm = (id) => () => {
    const filmPath = generatePath(APP_PATHS.film, {
      id: id,
    })
    navigate(filmPath)
  }

  const { data: films, isLoading } = useIndexFilms()

  return (
    <Box display="flex" justifyContent="flex-start" flexWrap="wrap">
      {!isEmpty(films) ? (
        films?.map((item) => {
          const onItemClick = handleNavigateToFilm(item.id)

          return (
            <Box key={item.id} width={`calc(100% / ${itemsPerRow})`} padding="18px">
              <FilmCard
                key={item.id}
                isLoading={isLoading}
                film={item}
                onItemClick={onItemClick}
              />
            </Box>
          )
        })
      ) : (
        <Box w="50%" p={{ base: '22px 20px', md: '22px 20px' }}>
          <Text variant="h6">No films to show</Text>
        </Box>
      )}
    </Box>
  )
}
