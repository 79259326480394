export var APP_PATHS
;((APP_PATHS) => {
  APP_PATHS['test'] = '/test' // For delete
  APP_PATHS['home'] = '/'
  APP_PATHS['movies'] = '/movies/'
  APP_PATHS['movie'] = `${APP_PATHS.movies}/:movieId`
  APP_PATHS['shows'] = '/shows/'
  APP_PATHS['show'] = `${APP_PATHS.movies}/:showId`
  APP_PATHS['study'] = '/study/'
  APP_PATHS['subtitle'] = '/subtitle/'
  APP_PATHS['filmsRecommendation'] = '/films-recommendation/'
  APP_PATHS['myProfile'] = '/profile/'
  APP_PATHS['terms'] = '/terms-and-condition/'
  APP_PATHS['whatWeDo'] = '/we-do/'
  APP_PATHS['confirmEmail'] = '/confirm-email/:token'
  APP_PATHS['resetPassword'] = '/reset-password/:token'
})(APP_PATHS || (APP_PATHS = {}))
