import React from 'react'
import { Image, Text, VStack } from '@chakra-ui/react'

export const CardCast = ({ imageUrl, name, character }) => {
  return (
    <VStack
      w="140px"
      borderRadius="10px"
      bg="linear-gradient(169deg, rgba(119, 124, 127, 0.39) 0%, rgba(119, 124, 127, 0.14) 100%)"
      p="8px"
      gap="4px"
      align="flex-start"
      my="8px"
      border="2px"
      borderColor="highliteLineGray"
    >
      <Image
        src={imageUrl}
        borderRadius="6px"
        maxH="180px"
        objectFit="fill"
        alt={`${name} as ${character}`}
      />
      <Text variant="h8" fontWeight="bold">
        {name}
      </Text>
      <Text variant="h8">{character}</Text>
    </VStack>
  )
}
