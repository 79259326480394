import React, { useState } from 'react'
import { useSpring, animated } from '@react-spring/web'
import { Box, Button, HStack, Image } from '@chakra-ui/react'

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

export const ImageSlider = ({ images = [] }) => {
  const [index, setIndex] = useState(0)

  const handleClick = (direction) => {
    setIndex((prevIndex) =>
      direction === 'next'
        ? (prevIndex + 1) % images.length
        : (prevIndex + images.length - 1) % images.length
    )
  }

  const fading = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    reset: true,
  })

  return (
    <Box position="relative" alignSelf="stretch" overflow="hidden">
      <animated.div style={fading}>
        <Image src={images[index]} width="100%" height="100%" objectFit="cover" />
      </animated.div>
      <HStack justifyContent="space-between" position="absolute" top="50%" width="100%" px={4}>
        <Button
          variant="link"
          borderRadius="4px"
          p="4px"
          bg="mainBlack"
          onClick={() => handleClick('prev')}
        >
          <ArrowBackIosNewIcon fontSize="large" />
        </Button>
        <Button
          variant="link"
          borderRadius="4px"
          p="4px"
          bg="mainBlack"
          onClick={() => handleClick('next')}
        >
          <ArrowForwardIosIcon fontSize="large" />
        </Button>
      </HStack>
    </Box>
  )
}
