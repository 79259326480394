import { MILLISECONDS_IN_DAY } from './const'

export const convertDateRelease = (date) => {
  const dateObj = new Date(date)
  const dateConverted = dateObj.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
  return dateConverted
}

export const yearRelease = (date) => {
  const dateReleaseObj = new Date(date)
  const year = dateReleaseObj.getFullYear()
  return year
}

export const convertDateToHumanFull = (date) => {
  const dateObj = new Date(date)
  const dateConverted = dateObj.toLocaleString('en-US', { hour12: false })
  return dateConverted
}

// Show Badge for NEW
export const badgeTitleNew = (date) => {
  const currentDate = new Date()
  const createdAtDate = new Date(date)
  const timeBetweenDates = currentDate - createdAtDate

  const isNew = timeBetweenDates / MILLISECONDS_IN_DAY <= 30

  return isNew
}
