import React, { useEffect, useRef, useState } from 'react'
import { Avatar, Box, Input } from '@chakra-ui/react'
import { CameraIcon } from '../../icons/CameraIcon'
export const FileUpload = ({ accept = 'image/*', preview, name, onChange }) => {
  const inputRef = useRef(null)
  const [selectedPreview, setSelectedPreview] = useState('')
  const handleChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0]
      setSelectedPreview(URL.createObjectURL(file))
      onChange && onChange(event.target.name, file)
    }
  }
  useEffect(() => {
    if (preview && !selectedPreview) {
      setSelectedPreview(preview)
    }
  }, [preview, selectedPreview])
  return (
    <Box
      w="130px"
      height="130px"
      borderRadius="50%"
      position="relative"
      cursor="pointer"
      onClick={() => inputRef.current?.click()}
    >
      <Avatar
        w="100%"
        height="100%"
        position="absolute"
        left="0"
        top="0"
        borderRadius="full"
        src={selectedPreview}
        zIndex="1"
      />
      <Box
        w="100%"
        h="100%"
        borderRadius="50%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        bg="rgba(0,0,0, .5)"
        zIndex="2"
      >
        <CameraIcon width="32px" height="32px" />
        <Input
          ref={inputRef}
          type="file"
          name={name}
          accept={accept}
          style={{ display: 'none' }}
          onChange={handleChange}
        />
      </Box>
    </Box>
  )
}
