import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import { Stack, SimpleGrid, Skeleton, Text } from '@chakra-ui/react'

import { Search } from '../../../components/Search/'
import { CardVideoBase } from '../../../components/Cards/CardVideoBase'

import { useIndexMyUserMovies } from 'shared/queries/userMovie'
import { useIndexFilms } from 'shared/queries/film'

export const TabItemsList = ({ variant, notFoundLabel }) => {
  const { t } = useTranslation()

  const query = { [variant]: true }

  const { data: indexMyUserMovies, isLoading: isIndexMyUserMoviesLoading } =
    useIndexMyUserMovies(query)

  const isBookmarkIds = indexMyUserMovies?.map((item) => item.filmId)

  const { data: indexBookmarkedFilms, isLoading: isIndexBookmarkedFilmsLoading } = useIndexFilms(
    { ids: isBookmarkIds }
  )

  return (
    <Stack
      justify="flex-start"
      align="center"
      spacing="28px"
      overflow="hidden"
      alignSelf="stretch"
    >
      <Search />
      <SimpleGrid
        layerStyle="outline"
        paddingX={{ base: '24px', sm: '30px', md: '40px' }}
        paddingY={{ base: '24px', sm: '40px' }}
        justifyItems="center"
        spacing="20px"
        minChildWidth="250px"
      >
        {(isIndexBookmarkedFilmsLoading || isIndexMyUserMoviesLoading) &&
          Array.from({ length: 4 }, (_, index) => (
            <Skeleton key={index} h="480px" w="250px" borderRadius="20px" />
          ))}
        {!isEmpty(isBookmarkIds) &&
          indexBookmarkedFilms?.map((movie) => (
            <CardVideoBase
              key={movie.id}
              id={movie.id}
              href={`/movies/${movie.id}`}
              imageUrl={movie.imageUrl}
              title={movie.title}
              tmdbRating={Math.round(movie.rating)}
              createdAt={movie.createdAt}
              duration={movie.duration}
            />
          ))}
        {isEmpty(isBookmarkIds) && (
          <Text variant="h2">
            {t(`It looks like you haven't added anything to`)} {notFoundLabel} .
          </Text>
        )}
      </SimpleGrid>
    </Stack>
  )
}
