// import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useToast } from '@chakra-ui/react'
import axios from 'shared/api/setup'

export const useRegisterUserMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/registerUser', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useRequestResetPasswordMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/requestResetPassword', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useResetPasswordMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.put('/resetPassword', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useResendVerificationEmailMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/resendVerificationEmail', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useCreateSessionByEmailMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.post('/createSessionByEmail', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useDeleteMySessionMutation = (options) => {
  const toast = useToast()
  return useMutation(async (data) => axios.delete('/deleteMySession', data), {
    onError: (error) => {
      toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
    },
    ...options,
  })
}

export const useVerifyEmailMutation = (options) => {
  const toast = useToast()
  return useMutation(
    (verificationToken) => axios.get('/verifyEmail', { params: { token: verificationToken } }),
    {
      onError: (error) => {
        toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
      },
      ...options,
    }
  )
}
