export const colors = {
  warning: '#EB5757',
  // pink: '#E8368F',
  teal: {
    400: '#4FD1C5',
    500: '#38B2AC',
    600: '#319795',
  },
  // brown1: '#9D482D',
  // brown2: '#65482D',
  // fluorescent: '#00FF94',
  white: '#ffffff',
  // primary: '#00BC71',
  // primaryHover: '#00A362',
  // primaryActive: '#008A53',
  // gray: {
  //   96: '#F4F3F9',
  //   79: '#C9C9CC',
  //   58: '#949496',
  //   50: '#7E7E83',
  //   36: '#5A5A5C',
  //   28: '#45474C',
  //   24: '#3A3B41',
  //   22: '#35363A',
  //   18: '#2C2D30',
  //   12: '#1D1E20',
  // },

  mainBlack: '#0F1114',
  mainPageBlack: '#120800',
  mainGray: '#1A1C20',
  textGreyLight: '#97A6A0',
  textGrey: '#777C7F',
  textGreyDark: '#56575B',
  textGreenMuted: '#53C153',
  highliteLineGray: '#323236',
  dividerLineGrey: '#3C3D44',
  filtersBackground: '#25262A',
  tealButton: '#319795',
  darkButton: '#1A1C20',
}
