import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Heading, Text, Button, Flex, VStack } from '@chakra-ui/react'

import { APP_PATHS } from 'paths'

import { setDocumentTitle } from 'components/PageElements/helpers'
import { Header } from 'components/PageElements/Header'
import { Footer } from 'components/PageElements/Footer'
// import { useUserContextState } from 'shared/contexts/user-context-provider'

export const NotFound = () => {
  const navigate = useNavigate()
  setDocumentTitle('404')
  //   const { user } = useUserContextState()

  // useEffect(() => {
  //   if (user.isLanguagesSet) {
  //     navigate(APP_PATHS.home)
  //   }
  // }, [user])

  return (
    <Flex direction="column" minH="100vh" bgColor="mainPageBlack">
      <Header />

      <VStack justifyContent="center" spacing={4} fit="contain" h={`calc(100vh - 277px)`}>
        <Heading
          display="inline-block"
          as="h2"
          size="2xl"
          bgGradient="linear(to-r, teal.400, teal.600)"
          backgroundClip="text"
        >
          404
        </Heading>
        <Text fontSize="18px" mt={3} mb={2}>
          Page Not Found
        </Text>
        <Text color={'gray.500'} mb={6}>
          The page you&apos;re looking for does not seem to exist
        </Text>

        <Button size="lg" onClick={() => navigate(APP_PATHS.home)}>
          Go to Home
        </Button>
      </VStack>

      <Footer />
    </Flex>
  )
}
