import { useQuery } from 'react-query'
import { useToast } from '@chakra-ui/react'
import axios from 'shared/api/setup'
import { QueriesKeysEnum } from './queries-keys-enum'

export const useDownloadSubtitle = (params = {}, enabled = true) => {
  const toast = useToast()
  const isURL = params.path !== ''

  return useQuery(
    [QueriesKeysEnum.subtitle, params],
    () => isURL && axios.get('/downloadSubtitle', { params }),
    {
      retry: false,
      enabled,
      onError: (error) => {
        toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
      },
    }
  )
}

export const useIndexMySubtitles = (params = {}, enabled = true) => {
  const toast = useToast()

  return useQuery(
    [QueriesKeysEnum.subtitle, params],
    () => axios.get('/indexMySubtitles', { params }),
    {
      retry: false,
      enabled,
      onError: (error) => {
        toast({ position: 'top-right', status: 'error', title: error.message, isClosable: true })
      },
    }
  )
}
