import React from 'react'
import { useTranslation } from 'react-i18next'
import { omitBy } from 'lodash'

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  VStack,
  Switch,
  useToast,
  Heading,
  Flex,
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FormField, FormFieldSelectSetup } from 'components/Modals/FormField'
import { useUpdateMyUserProfileMutation } from 'shared/mutations/userProfile'
import { useUserContextStateDispatch } from 'shared/contexts/user-context-provider'

export const TelegramBotSetup = ({ user, refetchUserProfile }) => {
  const { t } = useTranslation()
  const toast = useToast()

  const { mutate: updateMyUserProfile } = useUpdateMyUserProfileMutation()
  const { updateUserContext } = useUserContextStateDispatch()

  const validationSchema = Yup.object().shape({
    tgUserId: Yup.string().required(t(`Telegram name is required`)),
    // daysOfWeek: Yup.string().required(t(`Please select a day of the week`)),
    // timeReminder: Yup.string().required(t(`Please select a time for reminders`)),
    // notification: Yup.boolean(),
  })

  const initialValues = {
    tgUserId: user?.tgUserId || '',
    daysOfWeek: '',
    timeReminder: '',
    notification: false,
  }

  const handleUpdateTelegramSetup = (values) => {
    const filteredValues = omitBy(values, (value) => !value)

    updateMyUserProfile(filteredValues, {
      onSuccess: () => {
        updateUserContext({
          type: 'update',
          payload: { user: { ...user, ...filteredValues } },
        })
        refetchUserProfile()
        toast({
          position: 'top-right',
          status: 'success',
          title: t(`Telegram bot setup updated`),
          isClosable: true,
        })
      },
    })
  }

  return (
    <Box layerStyle="outline" p="24px">
      <Heading size="lg" textAlign="center">
        {t(`Telegram bot setup`)}
      </Heading>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdateTelegramSetup}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <VStack>
              <Flex wrap="wrap" justifyContent="center" alignItems="center" my="16px">
                <VStack spacing="16px" w="300px">
                  <FormField
                    name="tgUserId"
                    placeholder={t(`Telegram name`)}
                    minH="36px"
                    color="black"
                  />

                  <FormFieldSelectSetup
                    name="daysOfWeek"
                    placeholder={t(`Select days of week`)}
                    color="textGrey"
                    isDisabled={true}
                  >
                    <option value="monday">Monday</option>
                    <option value="tuesday">Tuesday</option>
                  </FormFieldSelectSetup>

                  <FormFieldSelectSetup
                    name="timeReminder"
                    placeholder={t(`Select time reminder to study`)}
                    color="textGrey"
                    isDisabled={true}
                  >
                    <option value="morning">Morning</option>
                    <option value="afternoon">Afternoon</option>
                  </FormFieldSelectSetup>

                  <FormControl display="flex" alignItems="center" w="300px">
                    <Switch
                      id="notification"
                      isChecked={values.notification}
                      onChange={() => setFieldValue('notification', !values.notification)}
                      mr="8px"
                      isDisabled={true}
                    />
                    <FormLabel htmlFor="notification" mb="0" color="gray.200">
                      {t(`Notification`)}
                    </FormLabel>
                  </FormControl>
                </VStack>
              </Flex>
              <Button size="lg" colorScheme="teal" type="submit">
                {t(`Save`)}
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
