import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { APP_PATHS } from 'paths'
import { Layout } from 'components/layout'

import {
  MainTest,
  HomePage,
  MoviesPage,
  Movie,
  TVShowsPage,
  SubtitlePage,
  StudyPage,
  FilmsRecommendationPage,
  MyProfilePage,
  TermsPage,
  WhatWeDoPage,
  ConfirmEmail,
  ResetPassword,
  NotFound,
} from './pages'

function App() {
  return (
    <Routes>
      <Route path={APP_PATHS.home} element={<HomePage />} />
      <Route path={APP_PATHS.home} element={<Layout />}>
        <Route path={APP_PATHS.movies}>
          <Route index element={<MoviesPage />} />
          <Route path={APP_PATHS.movie} element={<Movie />}></Route>
        </Route>
        <Route path={APP_PATHS.shows} element={<TVShowsPage />} />
        <Route path={APP_PATHS.study} element={<StudyPage />} />
        <Route path={APP_PATHS.subtitle} element={<SubtitlePage />} />
        <Route path={APP_PATHS.filmsRecommendation} element={<FilmsRecommendationPage />} />
        <Route path={APP_PATHS.myProfile} element={<MyProfilePage />} />

        <Route path={APP_PATHS.test} element={<MainTest />} />
      </Route>

      <Route path={APP_PATHS.terms} element={<TermsPage />} />
      <Route path={APP_PATHS.whatWeDo} element={<WhatWeDoPage />} />
      <Route path={APP_PATHS.confirmEmail} element={<ConfirmEmail />} />
      <Route path={APP_PATHS.resetPassword} element={<ResetPassword />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default App
