export const Menu = {
  baseStyle: {
    list: {
      bg: 'mainGray',
      p: '0',
      color: 'textGrey',
      overflow: 'hidden',
      borderColor: 'dividerLineGrey',
      zIndex: '10',
      minW: '120px',
    },
    item: {
      fontSize: 'lg',
      // fontWeight: 'bold',
      bg: 'mainGray',
      mb: '1px',
      // p: '10px 10px',
      _active: {
        bg: 'mainGray',
        color: 'tealButton',
      },
      _focus: {
        bg: 'mainGray',
        color: 'teal.500',
      },
      _hover: {
        color: 'white',
      },
      _checked: {
        color: 'white',
        'span.chakra-menu__icon-wrapper': {
          svg: {
            opacity: '1',
            color: 'white',
            bg: 'teal.500',
          },
        },
      },
      'span.chakra-menu__icon-wrapper': {
        '&:first-of-type': {
          opacity: '1',
          border: '2px solid',
          borderColor: 'teal.500',
          borderRadius: '2px',
        },
        svg: {
          opacity: '0',
        },
      },
    },
    divider: {
      borderColor: 'highliteLineGray',
      m: '0 10px',
    },
  },
}
