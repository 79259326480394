import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { useUserContextStateDispatch } from 'shared/contexts/user-context-provider'

import { LanguageSelectionModal, EmailVerificationExpired } from '.'
import { FormField } from './FormField'
import {
  useCreateSessionByEmailMutation,
  useRequestResetPasswordMutation,
} from 'shared/mutations/user'
import { useReadMyUserProfile } from 'shared/queries/userProfile'

export const LoginModal = ({ buttonSize }) => {
  const { t } = useTranslation()
  const toast = useToast()
  const queryCache = useQueryClient()
  const { updateUserContext } = useUserContextStateDispatch()
  const { mutate: onLogin } = useCreateSessionByEmailMutation()
  const { mutate: onRequestResetPassword } = useRequestResetPasswordMutation()
  const { data: user, refetch: refetchUser } = useReadMyUserProfile()

  const {
    isOpen: isLoginModalOpen,
    onOpen: onLoginModalOpen,
    onClose: onLoginModalClose,
  } = useDisclosure()
  const {
    isOpen: isRestoreModalOpen,
    onOpen: onRestoreModalOpen,
    onClose: onRestoreModalClose,
  } = useDisclosure()
  const {
    isOpen: isResendLinkOpen,
    onOpen: onResendLinkOpen,
    onClose: onResendLinkClose,
  } = useDisclosure()
  const {
    isOpen: isLanguageSelectionOpen,
    onOpen: onLanguageSelectionOpen,
    onClose: onLanguageSelectionClose,
  } = useDisclosure()

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t(`Invalid email`)).required(t(`Please Enter your email`)),
    password: Yup.string()
      .min(8, t(`Must Contain 8 Characters`))
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!@#$%^&*()\-_=+[{}\]|;:'",<.>/?€£¥₹])([a-zA-Z0-9`~!@#$%^&*()\-_=+[{}\]\\|;:'",<.>/?€£¥₹]+)(?=.{8,})/,
        t(`One Uppercase, One Lowercase, One Number and One Special Case Character`)
      )
      .required(t(`Please Enter your password`)),
  })

  const RestorePasswordSchema = Yup.object().shape({
    email: Yup.string().email(t(`Invalid email`)).required(t(`Please Enter your email`)),
  })

  const handleLogIn = async (values) => {
    onLogin(values, {
      onSuccess: async ({ token }) => {
        localStorage.setItem('AUTH_TOKEN', token)
        await refetchUser()
        await queryCache.refetchQueries()
      },
      onError: (response) => {
        const { error } = response
        if (error?.code === 'MMC-3') {
          onResendLinkOpen()
        }
      },
    })
  }

  const handleForgotPassword = async (values) => {
    onRequestResetPassword(values, {
      onSuccess: () => {
        onRestoreModalClose()
        toast({
          position: 'top-right',
          status: 'success',
          title: t(`Email with restore link send.`),
          isClosable: true,
        })
      },
    })
  }

  useEffect(() => {
    if (user) {
      updateUserContext({
        type: 'update',
        payload: {
          user,
        },
      })
      if (!user.isLanguagesSet) {
        onLoginModalClose()
        onLanguageSelectionOpen()
      }
    }
  }, [user])

  return (
    <>
      <Button size={buttonSize} onClick={onLoginModalOpen}>
        {t(`Log in`)}
      </Button>

      {/* Login Modal */}
      <Modal isOpen={isLoginModalOpen} onClose={onLoginModalClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW="350px">
          <ModalHeader>{t(`Login`)}</ModalHeader>

          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={LoginSchema}
            onSubmit={(values) => {
              handleLogIn(values)
            }}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <FormField name="email" placeholder={t(`Enter Email`)} />
                  <FormField name="password" type="password" placeholder={t(`Enter Password`)} />

                  <Button
                    variant="link"
                    onClick={() => {
                      onRestoreModalOpen()
                      onLoginModalClose()
                    }}
                  >
                    {t(`Forgot password?`)}
                  </Button>
                </ModalBody>
                <ModalFooter>
                  <Button size="lg" mr="16px" onClick={onLoginModalClose}>
                    {t(`Cancel`)}
                  </Button>
                  <Button size="lg" type="submit">
                    {t(`Login`)}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
      {/* Restore password */}
      <Modal isOpen={isRestoreModalOpen} onClose={onRestoreModalClose} isCentered>
        <ModalOverlay />
        <ModalContent maxW="350px">
          <ModalHeader>{t(`Restore password`)}</ModalHeader>
          <ModalCloseButton />

          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={RestorePasswordSchema}
            onSubmit={(values) => {
              handleForgotPassword(values)
            }}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <FormField name="email" placeholder={t(`Enter Email`)} />
                </ModalBody>
                <ModalFooter>
                  <Button size="lg" mr="16px" onClick={onRestoreModalClose}>
                    {t(`Cancel`)}
                  </Button>
                  <Button size="lg" type="submit">
                    {t(`Restore`)}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>

      <LanguageSelectionModal
        isLanguageSelectionOpen={isLanguageSelectionOpen}
        onLanguageSelectionClose={onLanguageSelectionClose}
      />
      <EmailVerificationExpired isOpen={isResendLinkOpen} onClose={onResendLinkClose} />
    </>
  )
}
