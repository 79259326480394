export const Tooltip = {
  baseStyle: {
    fontSize: 'sm',
    p: '8px 12px',
    lineHeight: 5,
    background: 'mainGray',
  },
  // variants: {
  //   compact: {
  //     px: '10px',
  //     py: '10px',
  //     borderRadius: '6px',
  //   },
  // },
}
